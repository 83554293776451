import { Button, Card, Col, Row, Spin, Statistic } from 'antd';
import { appInsights } from 'AppInsights';
import {
    BUTTON_AS_LINK,
    BUTTON_BLUE_SELECTED,
    BUTTON_BLUE_UN_SELECTED,
    FISH_GROUP_DETAIL_PAGE,
    PER_DAY_GRAPH,
    TOGGLE_FFI_PEN
} from 'common/constants';
import { formatDateTime, formatNumber, getDateRange } from 'common/utils';
import { FDLine } from 'components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedFishGroupId } from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';
import { getDeadFishCountingMortality, getDeadFishCountingMortalityByEventId } from 'redux/thunks';
import './index.scss';

const FishMortality = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [toggleState, setToggleState] = useState(TOGGLE_FFI_PEN.PEN);

    const { data: mortalityData, loading } = useSelector((s) => s.deadFishCountingMortality);
    const {
        data: eventDeadFishCountingMortalityData,
        loading: eventDeadFishCountingMortalityLoading
    } = useSelector((s) => s.eventDeadFishCountingMortality);

    const { data: eventInfo, loading: eventInfoLoading } = useSelector((s) => s.eventDetail.info);

    const chartData =
        toggleState === TOGGLE_FFI_PEN.PEN ? mortalityData : eventDeadFishCountingMortalityData;

    useEffect(() => {
        if (!eventInfo.id) return;

        if (toggleState === TOGGLE_FFI_PEN.PEN) {
            dispatch(
                getDeadFishCountingMortality({
                    penIds: [eventInfo.penId],
                    ...getDateRange(eventInfo.stopTime, 7, 7),
                    graphType: PER_DAY_GRAPH
                })
            );
        } else {
            dispatch(
                getDeadFishCountingMortalityByEventId({
                    id: eventInfo.id,
                    filters: getDateRange(eventInfo.stopTime, 7, 7)
                })
            );
        }
    }, [eventInfo.id, toggleState]);

    const formatFishGroupMortalityData = (fishGroupMortalityData) => {
        if (toggleState === TOGGLE_FFI_PEN.FFI)
            return {
                annotations: fishGroupMortalityData?.flatMap((item) => [
                    {
                        type: 'text',
                        position: [formatDateTime(item.day), 'max'],
                        content: item.name,
                        style: {
                            textBaseline: 'bottom'
                        }
                    },
                    {
                        type: 'line',
                        start: [formatDateTime(item.day), '0'],
                        end: [formatDateTime(item.day), 'max']
                    }
                ])
            };

        return { annotations: null };
    };

    const handleToggleStateChange = (state) => {
        setToggleState(state);

        appInsights.trackEvent({
            name: 'Event fish mortality toggle state change',
            properties: { filterBy: state }
        });
    };

    const handleFishGroupClick = (fishGroup) => {
        dispatch(setSelectedFishGroupId(fishGroup.id));

        navigate(FISH_GROUP_DETAIL_PAGE);

        appInsights.trackEvent({
            name: 'Fish group button click',
            properties: { rootLTreePath: fishGroup.rootLTreePath }
        });
    };

    return (
        <Card className="mt-2 fish-mortality-event-report">
            <Spin spinning={loading || eventDeadFishCountingMortalityLoading || eventInfoLoading}>
                <p className="font-medium text-base">
                    {t('mortalityAnalysis.fishMortality.title')}
                </p>

                <Row gutter={[8, 8]}>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                        <Row gutter={[8, 8]} className="h-full">
                            {chartData.counts?.map((item, index) => (
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={24}
                                    xl={24}
                                    key={index}
                                    className={item.fishName}
                                >
                                    <Card className="flex justify-center content-center h-full card-count">
                                        <Statistic
                                            title={t(`general.fishMortality.${item.fishName}`)}
                                            value={formatNumber(item.totalAmount)}
                                            valueStyle={{
                                                color: '#cf1322'
                                            }}
                                        />
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                        <Card>
                            <div className="flex justify-end">
                                <Button
                                    className={
                                        toggleState === TOGGLE_FFI_PEN.FFI
                                            ? BUTTON_BLUE_SELECTED
                                            : BUTTON_BLUE_UN_SELECTED
                                    }
                                    onClick={() => handleToggleStateChange(TOGGLE_FFI_PEN.FFI)}
                                    id="ffi"
                                    style={{ marginRight: '4px' }}
                                >
                                    {t('general.filterBar.fishGroups')}
                                </Button>

                                <Button
                                    className={
                                        toggleState === TOGGLE_FFI_PEN.PEN
                                            ? BUTTON_BLUE_SELECTED
                                            : BUTTON_BLUE_UN_SELECTED
                                    }
                                    onClick={() => handleToggleStateChange(TOGGLE_FFI_PEN.PEN)}
                                >
                                    {t('general.filterBar.pen')}
                                </Button>
                            </div>

                            <FDLine
                                data={chartData.charts?.map((item) => ({
                                    ...item,
                                    type: t(`general.fishMortality.${item.name}`),
                                    value: item.amount,
                                    name: formatDateTime(item.day)
                                }))}
                                chartConfig={formatFishGroupMortalityData(
                                    chartData.fishGroupMortalityReports
                                )}
                            />

                            <div className="flex xs:flex-col lg:flex-row mt-4">
                                <div className="flex-1">
                                    {TOGGLE_FFI_PEN.FFI &&
                                        chartData.fishGroupMortalityReports?.length > 0 && (
                                            <div className="mb-4 lg:mb-0 text-center lg:text-start">
                                                <span className="mr-4 font-semibold">
                                                    {t(
                                                        'dashboard.fishMortality.mortalityChart.listOfFishGroups'
                                                    )}
                                                </span>
                                                {chartData.fishGroupMortalityReports.map(
                                                    (fishGroup) => (
                                                        <button
                                                            className={`mr-4 inline-block ${BUTTON_AS_LINK}`}
                                                            key={fishGroup.id}
                                                            onClick={() =>
                                                                handleFishGroupClick(fishGroup)
                                                            }
                                                        >
                                                            {fishGroup.name}
                                                        </button>
                                                    )
                                                )}
                                            </div>
                                        )}
                                </div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </Card>
    );
};

export default FishMortality;
