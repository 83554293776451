import { createSlice } from '@reduxjs/toolkit';
import {
    getServiceBoats,
    getStreamingStressLevels,
    getStressEngineStatus,
    setVideoUpdateStatus,
    stopStressEngine
} from 'redux/thunks';

const initialState = {
    serviceBoats: { data: [], loading: false },
    streaming: {
        isDetecting: false,
        engineBaseUrl: null,
        serviceBoatId: null,
        serviceBoatName: null,
        penNumber: null,
        stressLevels: [],
        uploadAfterStop: false
    }
};

export const liveStressDetectionSlice = createSlice({
    name: 'liveStressDetection',
    initialState,
    reducers: {
        resetStressDetectionServiceBoats: (state) => {
            state.serviceBoats = initialState.serviceBoats;
        },
        resetStressDetectionStreaming: (state) => {
            state.streaming = initialState.streaming;
        },
        setStressDetectionStreaming: (state, action) => {
            state.streaming = { ...state.streaming, ...action.payload };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getServiceBoats.pending, (state) => {
                state.serviceBoats.loading = true;
            })
            .addCase(getServiceBoats.fulfilled, (state, action) => {
                state.serviceBoats.loading = false;
                state.serviceBoats.data = action.payload || initialState.serviceBoats.data;
            })
            .addCase(getServiceBoats.rejected, (state) => {
                state.serviceBoats.loading = false;
                state.serviceBoats.data = initialState.serviceBoats.data;
            })

            .addCase(getStressEngineStatus.pending, (state, action) => {
                const { serviceBoatId } = action.meta.arg;
                const index = state.serviceBoats.data.findIndex(
                    (boat) => boat.id === serviceBoatId
                );

                const newServiceBoatArray = [...state.serviceBoats.data];
                newServiceBoatArray[index] = { ...state.serviceBoats.data[index], loading: true };
                state.serviceBoats.data = newServiceBoatArray;
            })
            .addCase(getStressEngineStatus.fulfilled, (state, action) => {
                const { serviceBoatId } = action.meta.arg;
                const index = state.serviceBoats.data.findIndex(
                    (boat) => boat.id === serviceBoatId
                );

                const newServiceBoatArray = [...state.serviceBoats.data];
                newServiceBoatArray[index] = {
                    ...state.serviceBoats.data[index],
                    ...action.payload,
                    loading: false
                };
                state.serviceBoats.data = newServiceBoatArray;
            })
            .addCase(getStressEngineStatus.rejected, (state, action) => {
                const { serviceBoatId } = action.meta.arg;
                const index = state.serviceBoats.data.findIndex(
                    (boat) => boat.id === serviceBoatId
                );

                const newServiceBoatArray = [...state.serviceBoats.data];
                newServiceBoatArray[index] = { ...state.serviceBoats.data[index], loading: false };
                state.serviceBoats.data = newServiceBoatArray;
            })

            .addCase(stopStressEngine.pending, (state, action) => {
                const { serviceBoatId } = action.meta.arg;
                const index = state.serviceBoats.data.findIndex(
                    (boat) => boat.id === serviceBoatId
                );

                const newServiceBoatArray = [...state.serviceBoats.data];
                newServiceBoatArray[index] = { ...state.serviceBoats.data[index], loading: true };
                state.serviceBoats.data = newServiceBoatArray;
            })
            .addCase(stopStressEngine.fulfilled, (state, action) => {
                const { serviceBoatId } = action.meta.arg;
                const index = state.serviceBoats.data.findIndex(
                    (boat) => boat.id === serviceBoatId
                );

                const newServiceBoatArray = [...state.serviceBoats.data];
                newServiceBoatArray[index] = { ...state.serviceBoats.data[index], loading: false };

                state.serviceBoats.data = newServiceBoatArray;
            })
            .addCase(stopStressEngine.rejected, (state, action) => {
                const { serviceBoatId } = action.meta.arg;
                const index = state.serviceBoats.data.findIndex(
                    (boat) => boat.id === serviceBoatId
                );

                const newServiceBoatArray = [...state.serviceBoats.data];
                newServiceBoatArray[index] = { ...state.serviceBoats.data[index], loading: false };

                state.serviceBoats.data = newServiceBoatArray;
            })

            .addCase(getStreamingStressLevels.fulfilled, (state, action) => {
                state.streaming.stressLevels = action.payload;
            })
            .addCase(getStreamingStressLevels.rejected, (state) => {
                state.streaming.stressLevels = initialState.streaming.stressLevels;
            })

            .addCase(setVideoUpdateStatus.fulfilled, (state, action) => {
                state.streaming.uploadAfterStop = action.payload?.uploadAfterStop;
            })
            .addCase(setVideoUpdateStatus.rejected, (state) => {
                state.streaming.stressLevels = initialState.streaming.stressLevels;
            });
    }
});

export const {
    resetStressDetectionServiceBoats,
    resetStressDetectionStreaming,
    setStressDetectionStreaming
} = liveStressDetectionSlice.actions;
