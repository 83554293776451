import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    GlobalOutlined,
    PlayCircleOutlined,
    RestOutlined,
    RollbackOutlined,
    WarningOutlined
} from '@ant-design/icons';
import { Button, Space, Spin, Tabs } from 'antd';
import { appInsights } from 'AppInsights';
import useAppAbility from 'casl/can';
import {
    ADMINISTRATOR,
    BLUE,
    BUTTON_BLUE_CLASS,
    BUTTON_GRAY_CLASS,
    BUTTON_LIGHT_GREEN_CLASS,
    BUTTON_RED_CLASS,
    LIGHT_GRAY,
    SITE_MANAGER,
    STRESS_EVENT_PAGE,
    SUCCESSFULLY_DELETION_MESSAGE,
    SUCCESSFULLY_RESTORE_MESSAGE,
    TABLE_COLUMNS,
    VIDEO_STATUS
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { FDTable } from 'components';
import FDMediaPlayer from 'components/common/MediaPlayer/FDMediaPlayer';
import { FiltersContext } from 'context/filtersContext';
import { isEmpty } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedStressEventId } from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';
import {
    deleteStressEvent,
    getEventList,
    requestEngineStoreStressEventVideo,
    restoreStressEvent
} from 'redux/thunks';
import Swal from 'sweetalert2';
import StressEventForm from '../StressEventForm';

const TableEventList = () => {
    const { t } = useTranslation();
    const { can } = useAppAbility();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const filters = useContext(FiltersContext);
    const { data: events, loading } = useSelector((s) => s.eventList);
    const [isDeleteTab, setIsDeleteTab] = useState(false);
    const [stressEventFormModalState, setStressEventFormModalState] = useState({
        open: false,
        stressEvent: null
    });
    const [playerModalState, setPlayerModalState] = useState({
        open: false,
        playing: false,
        videoURLs: []
    });

    useEffect(() => {
        if (isEmpty(filters)) return;
        dispatch(getEventList({ ...filters, isDeleted: isDeleteTab }));
    }, [isDeleteTab, filters]);

    const handleTabChange = (isDeleted) => {
        setIsDeleteTab(isDeleted);

        appInsights.trackEvent({ name: 'Event list tab change', properties: { isDeleted } });
    };

    const handleEventClick = (eventId) => {
        dispatch(setSelectedStressEventId(eventId));

        navigate(STRESS_EVENT_PAGE);

        appInsights.trackEvent({
            name: 'Stress event detail button click',
            properties: { eventId }
        });
    };

    const handleStressEventFormShowModal = (record) => {
        setStressEventFormModalState({ open: true, stressEvent: record });

        appInsights.trackEvent({
            name: 'Stress event edit button click',
            properties: { eventId: record.id }
        });
    };

    const handleDeleteEvent = (id = null) => {
        Swal.fire({
            title: t('general.action.deleteModal.title'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: BLUE,
            cancelButtonColor: LIGHT_GRAY,
            confirmButtonText: t('general.action.deleteModal.confirmText'),
            cancelButtonText: t('general.action.deleteModal.cancelText')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(deleteStressEvent(id)).unwrap();

                    dispatch(getEventList({ ...filters, isDeleted: isDeleteTab }));
                    alertSuccessMessage(t(SUCCESSFULLY_DELETION_MESSAGE));
                } catch (err) {
                    alertErrorMessage(err, t);
                }
            }
        });

        appInsights.trackEvent({ name: 'Stress event delete', properties: { eventId: id } });
    };

    const handleRestore = (id) => {
        Swal.fire({
            title: t('general.action.restoreModal.title'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: BLUE,
            cancelButtonColor: LIGHT_GRAY,
            confirmButtonText: t('general.action.restoreModal.confirmText'),
            cancelButtonText: t('general.action.cancel')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(restoreStressEvent(id)).unwrap();

                    dispatch(getEventList({ ...filters, isDeleted: isDeleteTab }));
                    alertSuccessMessage(t(SUCCESSFULLY_RESTORE_MESSAGE));
                } catch (err) {
                    alertErrorMessage(err, t);
                }
            }
        });

        appInsights.trackEvent({ name: 'Stress event restore', properties: { eventId: id } });
    };

    const playVideo = (videoURLs) => {
        setPlayerModalState({ open: true, videoURLs, playing: true });
    };

    const requestVideo = (eventId) => {
        dispatch(requestEngineStoreStressEventVideo(eventId));
    };

    const onClosePlayerModal = () => {
        setPlayerModalState({ open: false, videoURLs: [], playing: false });
    };

    const COLUMNS_FOR_EVENT_LIST = [
        TABLE_COLUMNS.SITE,
        TABLE_COLUMNS.PEN,
        TABLE_COLUMNS.BOAT,
        TABLE_COLUMNS.BOAT_COMPANY,
        TABLE_COLUMNS.START_TIME,
        TABLE_COLUMNS.STOP_TIME,
        {
            title: 'general.table.video',
            dataIndex: 'video',
            render: (_, record) => (
                <Space size="middle">
                    {record?.isRequestable ? (
                        <Button
                            className={BUTTON_BLUE_CLASS}
                            onClick={() => requestVideo(record.id)}
                        >
                            <span>{t('general.action.requestVideo')}</span>
                        </Button>
                    ) : record?.videoStatus === VIDEO_STATUS.UPLOADING ? (
                        <span className="italic text-gray-500">{t('status.uploading')}</span>
                    ) : record?.videoStatus === VIDEO_STATUS.FAILED ? (
                        <span>
                            <WarningOutlined className="text-red-500 mr-1" />
                            <span>{t('status.failed')}</span>
                        </span>
                    ) : (
                        record.videoURLs?.length > 0 && (
                            <Button
                                className={BUTTON_LIGHT_GREEN_CLASS}
                                onClick={() => playVideo(record.videoURLs)}
                            >
                                <PlayCircleOutlined />
                                <span>{t('general.action.watch')}</span>
                            </Button>
                        )
                    )}
                </Space>
            )
        },
        {
            title: 'general.table.action',
            dataIndex: 'Action',
            render: (_, record) => (
                <Space size="middle">
                    {isDeleteTab ? (
                        <Button
                            className={`restore-stress-event ${BUTTON_GRAY_CLASS}`}
                            onClick={() => handleRestore(record.id)}
                        >
                            <RollbackOutlined />
                            <span>{t('general.action.restore')}</span>
                        </Button>
                    ) : (
                        <>
                            <Button
                                className={BUTTON_BLUE_CLASS}
                                onClick={() => {
                                    handleEventClick(record.id);
                                }}
                            >
                                <EyeOutlined />
                                <span>{t('stressEventList.detail')}</span>
                            </Button>

                            {can('manage', SITE_MANAGER, ['all']) && (
                                <Button
                                    className={BUTTON_GRAY_CLASS}
                                    onClick={() => handleStressEventFormShowModal(record)}
                                >
                                    <EditOutlined />
                                    <span>{t('general.action.edit')}</span>
                                </Button>
                            )}

                            {can('manage', ADMINISTRATOR, ['all']) && (
                                <Button
                                    className={`delete-boat ${BUTTON_RED_CLASS}`}
                                    onClick={() => handleDeleteEvent(record.id)}
                                >
                                    <DeleteOutlined />
                                    <span>{t('general.action.delete')}</span>
                                </Button>
                            )}
                        </>
                    )}
                </Space>
            )
        }
    ];

    return (
        <div className="mt-5">
            <h2 className="mb-2">{t('stressEventList.events')}</h2>
            <Spin spinning={loading}>
                <Tabs
                    className="site-tabs"
                    activeKey={isDeleteTab}
                    animated={true}
                    onChange={handleTabChange}
                    items={[
                        {
                            label: (
                                <span className="active-stress-event-list">
                                    <GlobalOutlined />
                                    <span>{t('stressEventList.actived')}</span>
                                </span>
                            ),
                            key: false,
                            children: <FDTable headers={COLUMNS_FOR_EVENT_LIST} data={events} />
                        },
                        {
                            label: (
                                <span className="deleted-stress-event-list">
                                    <RestOutlined />
                                    <span>{t('stressEventList.deleted')}</span>
                                </span>
                            ),
                            key: true,
                            children: <FDTable headers={COLUMNS_FOR_EVENT_LIST} data={events} />
                        }
                    ]}
                />
            </Spin>
            <FDMediaPlayer
                playing={playerModalState.playing}
                videoURLs={playerModalState.videoURLs}
                onClosePlayerModal={onClosePlayerModal}
                open={playerModalState.open}
            />
            <StressEventForm
                open={stressEventFormModalState.open}
                stressEvent={stressEventFormModalState.stressEvent}
                onClose={() => setStressEventFormModalState({ open: false, stressEvent: null })}
            />
        </div>
    );
};

export default TableEventList;
