import { appInsights } from 'AppInsights';
import { Card, Col, Row } from 'antd';
import {
    BUTTON_AS_LINK,
    DATE_TIME_FORMAT,
    FISH_GROUP_DETAIL_PAGE,
    ORANGE,
    OTHER_ATTRIBUTES,
    RED,
    SILVER
} from 'common/constants';
import { formatDateTime, formatNumber } from 'common/utils';
import { FDPercentage } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { mortalityDetailSelector, mortalityProductionFishSelector } from 'redux/selector';
import { setSelectedFishGroupId } from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';

const MortalityInfo = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { t } = useTranslation();

    const { data: mortality } = useSelector(mortalityDetailSelector);
    const productionFish = useSelector(mortalityProductionFishSelector);

    const diagnosticAttribute = productionFish.diagnosticAttributes?.reduce((acc, obj) => {
        acc[obj.name] = obj.value;
        return acc;
    }, {});

    const infoList = [
        {
            key: t('mortality.location'),
            value:
                mortality.siteName &&
                mortality.penNumber &&
                `${mortality.siteName} - ${mortality.penNumber}`,
            col: { xs: 12, xl: 6, xxl: 7 }
        },
        {
            key: t('mortality.fishGroup'),
            value: mortality.fishGroups?.map((fishGroup) => (
                <button
                    className={`mr-4 inline-block ${BUTTON_AS_LINK}`}
                    key={fishGroup.id}
                    onClick={() => handleFishGroupClick(fishGroup)}
                >
                    {fishGroup.fishGroupId}-{fishGroup.sibling}
                </button>
            )),
            col: { xs: 12, xl: 6, xxl: 7 }
        },
        {
            key: t('mortality.time'),
            value: (
                <>
                    <span>{formatDateTime(mortality.startTime, DATE_TIME_FORMAT)}</span>
                    {' - '}
                    <span className="inline-block">
                        {formatDateTime(mortality.stopTime, DATE_TIME_FORMAT)}
                    </span>
                </>
            ),
            col: { xs: 24, sm: 12, xl: 12, xxl: 10 }
        },
        {
            key: t('general.fishDiagnosticAttribute.numberOfAnalysis'),
            value: formatNumber(diagnosticAttribute?.number_of_analyze),
            col: { xs: 12, xl: 6, xxl: 7 }
        },
        {
            key: t('general.fishDiagnosticAttribute.averageLength'),
            value: formatNumber(diagnosticAttribute?.average_length),
            col: { xs: 12, xl: 6, xxl: 7 }
        },
        {
            key: t('mortality.fishCount'),
            value: mortality.fishes
                ?.map(
                    (fish) =>
                        `${t(`general.fishMortality.${fish.fishName}`)}: ${formatNumber(
                            fish.count
                        )}`
                )
                .join(', '),
            col: { xs: 12, xl: 12, xxl: 10 }
        },
        ...OTHER_ATTRIBUTES.map((type) => {
            const data = productionFish.attributes?.find((item) => item.name === type.id);

            return {
                key: t(type.text),
                value: formatNumber(data?.count),
                col: { xs: 12, xl: 6, xxl: 7 }
            };
        })
    ];

    const handleFishGroupClick = (fishGroup) => {
        dispatch(setSelectedFishGroupId(fishGroup.id));

        navigate(FISH_GROUP_DETAIL_PAGE);

        appInsights.trackEvent({
            name: 'Fish group button click',
            properties: { rootLTreePath: fishGroup.rootLTreePath }
        });
    };

    return (
        <Card className="event-info mt-2">
            <Row className="text-base" gutter={8}>
                <Col xs={24} xl={16} xxl={17}>
                    <Row>
                        {infoList.map((info) => (
                            <Col key={info.key} {...info.col} className="flex flex-col mb-5">
                                <span className="font-semibold">{info.key}</span>
                                <span>{info.value}</span>
                            </Col>
                        ))}
                    </Row>
                </Col>

                <Col xs={24} xl={8} xxl={7}>
                    <Row className="items-center h-full">
                        <Col xs={12}>
                            <span className="font-semibold">
                                {t('general.fishDiagnosticAttribute.woundPercentage')}
                            </span>
                            <FDPercentage
                                height={110}
                                width={110}
                                value={diagnosticAttribute?.wound_percentage}
                                color={[RED, SILVER]}
                            />
                        </Col>

                        <Col xs={12}>
                            <span className="font-semibold">
                                {t('general.fishDiagnosticAttribute.sizeOutlier')}
                            </span>
                            <FDPercentage
                                height={110}
                                width={110}
                                value={diagnosticAttribute?.size_outlier}
                                color={[ORANGE, SILVER]}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
};

export default MortalityInfo;
