import { DownloadOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { appInsights } from 'AppInsights';
import { BUTTON_BLUE_CLASS, FILE_EXPORT_FORMAT_DATE, FISH_ATTRIBUTES } from 'common/constants';
import { exportZipOfImages } from 'common/utils';
import { WoundAndSizeAttachment } from 'components';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ViewMoreAttachments = ({ open = false, attributes = [], onClose = () => {} }) => {
    const { t } = useTranslation();
    const [isDownloading, setIsDownloading] = useState(false);

    const combinedAttributes = FISH_ATTRIBUTES.map((attribute) => {
        const match = attributes.find((item) => item.name === attribute.id);
        return { ...attribute, ...match };
    });

    const downloadAttachments = async () => {
        if (isEmpty(attributes)) return;

        setIsDownloading(true);

        const folders = combinedAttributes.map((attribute) => ({
            name: t(attribute.text),
            imageUrls: attribute.attachments
        }));
        const fileName = `${t('dailyReport.report.table.woundAndSizeImages')} - ${moment().format(
            FILE_EXPORT_FORMAT_DATE
        )}`;

        await exportZipOfImages(folders, fileName);

        setIsDownloading(false);
        appInsights.trackEvent({ name: 'Wound and size images download' });
    };

    return (
        <div>
            <Modal
                title={t('dailyReport.report.table.woundAndSizeImages')}
                open={open}
                onCancel={() => onClose()}
                width={800}
                footer={
                    <>
                        <Button onClick={() => onClose()}>{t('button.close')}</Button>
                        <Button
                            onClick={() => downloadAttachments()}
                            disabled={isEmpty(attributes)}
                            className={BUTTON_BLUE_CLASS}
                            icon={<DownloadOutlined />}
                            loading={isDownloading}
                        >
                            {t('button.download')}
                        </Button>
                    </>
                }
            >
                {combinedAttributes.map((attribute) => (
                    <div className="mb-2" key={attribute.id}>
                        <span className="font-medium">{t(attribute.text)}</span>
                        <WoundAndSizeAttachment
                            attachments={attribute.attachments}
                            limit={attribute.attachments?.length}
                        />
                    </div>
                ))}
            </Modal>
        </div>
    );
};

ViewMoreAttachments.propTypes = {
    open: PropTypes.bool,
    attributes: PropTypes.array,
    onClose: PropTypes.func
};

export default ViewMoreAttachments;
