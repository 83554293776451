import { appInsights } from 'AppInsights';
import { Button, Col, Row } from 'antd';
import {
    BUTTON_AS_LINK,
    BUTTON_GRAY_SELECTED,
    BUTTON_GRAY_UN_SELECTED,
    COUNT_RANGE_SELECT,
    DEAD_FISH_COUNTING_REPORT_NAME,
    EXPORT_ALL,
    EXPORT_BY_FILTER,
    EXPORT_DEAD_FISH_COUNTING_REPORT_API,
    FISH_GROUP_DETAIL_PAGE,
    PER_COUNT_REPORT,
    PER_DAY_REPORT,
    PRODUCTION_FISH_NAMES,
    TABLE_COLUMNS
} from 'common/constants';
import {
    ExpandRow,
    FDDropdownDownload,
    FDSelection,
    FDTable,
    ViewMoreAttachments
} from 'components';
import { FiltersContext } from 'context/filtersContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import isEmpty from 'lodash/isEmpty';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    setFromDead,
    setReportType,
    setSelectedFishGroupId,
    setToDead
} from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';
import { getReportTable } from 'redux/thunks';

const ReportTable = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { expandRowMortalityReport } = useFlags();

    const filters = useContext(FiltersContext);

    const fromDead = useSelector((s) => s.filter.data.fromDead);
    const toDead = useSelector((s) => s.filter.data.toDead);
    const reportType = useSelector((s) => s.filter.data.reportType);
    const { data, loading } = useSelector((s) => s.reportTable);

    const FISH_GROUPS_COLUMN = {
        title: 'general.table.fishGroups',
        dataIndex: 'fishGroups',
        className: 'table-report-fish-groups',
        render: (fishGroups) =>
            fishGroups.map((fishGroup) => (
                <button
                    className={`mr-4 inline-block ${BUTTON_AS_LINK}`}
                    key={fishGroup.id}
                    onClick={() => handleFishGroupClick(fishGroup)}
                >
                    {fishGroup.fishGroupId}-{fishGroup.sibling}
                </button>
            )),
        isDefault: true
    };

    const COLUMNS_FOR_PER_COUNT_REPORT = [
        TABLE_COLUMNS.SITE,
        TABLE_COLUMNS.PEN,
        FISH_GROUPS_COLUMN,
        TABLE_COLUMNS.START_TIME,
        TABLE_COLUMNS.STOP_TIME,
        TABLE_COLUMNS.LAKS,
        TABLE_COLUMNS.ØRRET,
        TABLE_COLUMNS.BERGGYLT,
        TABLE_COLUMNS.ROGNKJEKS
    ];

    const COLUMNS_FOR_PER_DAY_REPORT = [
        TABLE_COLUMNS.DATE,
        TABLE_COLUMNS.SITE,
        TABLE_COLUMNS.PEN,
        FISH_GROUPS_COLUMN,
        TABLE_COLUMNS.LAKS,
        TABLE_COLUMNS.ØRRET,
        TABLE_COLUMNS.BERGGYLT,
        TABLE_COLUMNS.ROGNKJEKS
    ];

    const [columns, setColumns] = useState(COLUMNS_FOR_PER_COUNT_REPORT);
    const [modalState, setModalState] = useState({ open: false, attributes: [] });
    const [downloadOptions, setDownloadOptions] = useState([]);

    useEffect(() => {
        if (isEmpty(filters)) return;

        const newFilters = {
            ...filters,
            fromDead: fromDead,
            toDead: toDead,
            reportType: reportType
        };

        dispatch(getReportTable(newFilters));

        setDownloadOptions([
            {
                label: t('mortalityAnalysis.reportTable.download.byCurrentFilter'),
                params: { ...newFilters, exportType: EXPORT_BY_FILTER },
                className: 'download-current-filter-data-button'
            },
            {
                label: t('mortalityAnalysis.reportTable.download.allData'),
                params: { ...newFilters, exportType: EXPORT_ALL },
                className: 'download-all-data-button'
            }
        ]);
    }, [filters, fromDead, toDead, reportType]);

    const handleFishGroupClick = (fishGroup) => {
        dispatch(setSelectedFishGroupId(fishGroup.id));

        navigate(FISH_GROUP_DETAIL_PAGE);

        appInsights.trackEvent({
            name: 'Fish group button click',
            properties: { rootLTreePath: fishGroup.rootLTreePath }
        });
    };

    const handleReportTypeChange = (newType) => {
        if (newType === PER_COUNT_REPORT) {
            setColumns(COLUMNS_FOR_PER_COUNT_REPORT);
        } else {
            setColumns(COLUMNS_FOR_PER_DAY_REPORT);
        }

        dispatch(setReportType(newType));

        appInsights.trackEvent({
            name: 'Report table type change',
            properties: { type: newType }
        });
    };

    const handleDeadChange = (value, object) => {
        dispatch(setFromDead(object?.from ?? null));
        dispatch(setToDead(object?.to ?? null));

        appInsights.trackEvent({
            name: 'Dead fish count range change',
            properties: { from: object?.from, to: object?.to }
        });
    };

    const convertColumns = (columns) => {
        if (filters.fishNames?.length) {
            columns = columns.filter(
                (col) => filters.fishNames.includes(col.dataIndex) || col.isDefault
            );
        }

        return columns;
    };

    const convertReportData = (reports) =>
        reports.map((item, index) => {
            const { fishes, ...rest } = item;
            const fishData = fishes.reduce(
                (accumulator, { fishId, dead }) => ({
                    ...accumulator,
                    [fishId]: dead
                }),
                { key: index }
            );

            const productionFish = fishes.find((fish) =>
                PRODUCTION_FISH_NAMES.some((productionFish) => productionFish.id === fish.fishId)
            );

            return {
                ...rest,
                ...fishData,
                attributes: productionFish?.fishAttributes,
                diagnosticAttributes: productionFish?.fishDiagnosticAttributes?.reduce(
                    (acc, obj) => {
                        acc[obj.name] = obj.value;
                        return acc;
                    },
                    {}
                )
            };
        });

    const expandedRowRender = (record) => (
        <ExpandRow
            diagnosticAttributes={record.diagnosticAttributes}
            attributes={record.attributes}
            viewMoreAttachments={() => setModalState({ open: true, attributes: record.attributes })}
        />
    );

    return (
        <div className="mt-5">
            <h2 className="mb-2">{t('mortalityAnalysis.reportTable.title')}</h2>
            <Row className="flex justify-between w-full mb-1">
                <Col className="mr-4 flex xs:justify-center xl:justify-start py-1" xs={24} xl={8}>
                    <Button
                        disabled={loading}
                        className={
                            reportType === PER_COUNT_REPORT
                                ? BUTTON_GRAY_SELECTED
                                : BUTTON_GRAY_UN_SELECTED
                        }
                        onClick={() => handleReportTypeChange(PER_COUNT_REPORT)}
                    >
                        {t('mortalityAnalysis.reportTable.perCount')}
                    </Button>
                    &nbsp;
                    <Button
                        id="per-day"
                        disabled={loading}
                        className={
                            reportType === PER_DAY_REPORT
                                ? BUTTON_GRAY_SELECTED
                                : BUTTON_GRAY_UN_SELECTED
                        }
                        onClick={() => handleReportTypeChange(PER_DAY_REPORT)}
                    >
                        {t('mortalityAnalysis.reportTable.perDay')}
                    </Button>
                </Col>

                <Col className="flex xs:justify-between xl:justify-end py-1" xs={24} xl={15}>
                    <div className="xs:flex-col sm:flex-row mortality-count-filter">
                        <span className="font-semibold title text-base text-gray-700">
                            {t('mortalityAnalysis.reportTable.mortalityCount.title')}{' '}
                        </span>
                        <FDSelection
                            placeholder={t(
                                'mortalityAnalysis.reportTable.mortalityCount.placeholder'
                            )}
                            className="w-52 select xl:mr-10 mortality-count"
                            listSelectItem={COUNT_RANGE_SELECT.map((range) => ({
                                ...range,
                                text: t(range.text)
                            }))}
                            onChange={handleDeadChange}
                            allowClear={true}
                        />
                    </div>
                    <FDDropdownDownload
                        options={downloadOptions}
                        exportApi={EXPORT_DEAD_FISH_COUNTING_REPORT_API}
                        fileName={DEAD_FISH_COUNTING_REPORT_NAME}
                    />
                </Col>
            </Row>

            <FDTable
                headers={convertColumns(columns)}
                data={convertReportData(data)}
                className="report-table"
                loading={loading}
                showExpandColumn={expandRowMortalityReport}
                expandable={{ expandedRowRender }}
            />

            <ViewMoreAttachments
                open={modalState.open}
                attributes={modalState.attributes}
                onClose={() => setModalState({ open: false, attributes: [] })}
            />
        </div>
    );
};

export default ReportTable;
