import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import Flow from './Flow';
import ProcessInfo from './ProcessInfo';
import StressZone from './StressZone';
import WelfareScore from './WelfareScore';

const ProcessDetail = () => {
    const selectedProcessId = useSelector((s) => s.filter.data.selectedProcessId);

    if (!selectedProcessId) return;

    return (
        <>
            <ProcessInfo />
            <Row gutter={8}>
                <Col xs={24} xl={12}>
                    <StressZone />
                </Col>
                <Col xs={24} xl={12}>
                    <Flow />
                </Col>
                <Col xs={24}>
                    <WelfareScore />
                </Col>
            </Row>
        </>
    );
};

export default ProcessDetail;
