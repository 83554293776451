import { Button, Card, Col, Row, Spin } from 'antd';
import { appInsights } from 'AppInsights';
import {
    BUTTON_BLUE_SELECTED,
    BUTTON_BLUE_UN_SELECTED,
    LIGHT_GRAY,
    WOUND_TYPE_WITH_DEAD_FISH_COUNT_GRAPH,
    WOUND_TYPE_WITH_STOCK_COUNT_GRAPH
} from 'common/constants';
import { convertSnakeCaseToCamelCase, formatDateTime, formatNumber } from 'common/utils';
import { FDStackedColumn } from 'components';
import { FiltersContext } from 'context/filtersContext';
import isEmpty from 'lodash/isEmpty';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { setWoundTypeReportType } from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';
import {
    getMortalityAnalysisWoundTypeDeadFishCountReport,
    getMortalityAnalysisWoundTypeStockCountReport
} from 'redux/thunks';

const WoundType = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const filters = useContext(FiltersContext);

    const woundTypeReportType = useSelector((s) => s.filter.data.woundTypeReportType);
    const { woundTypeWithDeadFishCount, woundTypeWithStockCount, loading } = useSelector(
        (s) => s.mortalityAnalysisWoundTypeReport
    );

    useEffect(() => {
        if (isEmpty(filters)) return;

        if (woundTypeReportType === WOUND_TYPE_WITH_DEAD_FISH_COUNT_GRAPH) {
            dispatch(getMortalityAnalysisWoundTypeDeadFishCountReport(filters));
        } else {
            dispatch(getMortalityAnalysisWoundTypeStockCountReport(filters));
        }
    }, [filters, woundTypeReportType]);

    const handleGraphTypeChange = (newType) => {
        dispatch(setWoundTypeReportType(newType));

        appInsights.trackEvent({
            name: 'Mortality analysis wound type report type change',
            properties: { type: newType }
        });
    };

    const woundTypeReport =
        woundTypeReportType === WOUND_TYPE_WITH_DEAD_FISH_COUNT_GRAPH
            ? woundTypeWithDeadFishCount?.flatMap((item) => [
                  {
                      name: formatDateTime(item.reportDate),
                      value: item.attributeCount,
                      type: t(
                          `general.fishAttribute.${convertSnakeCaseToCamelCase(item.attributeName)}`
                      ),
                      percentage:
                          item.deadFishCount > 0 && item.attributeCount !== 0
                              ? formatNumber((item.attributeCount * 100) / item.deadFishCount) + '%'
                              : ''
                  }
              ])
            : woundTypeWithStockCount?.flatMap((item) => [
                  {
                      name: formatDateTime(item.reportDate),
                      value: item.attributeCount,
                      type: t(
                          `general.fishAttribute.${convertSnakeCaseToCamelCase(item.attributeName)}`
                      ),
                      percentage:
                          item.remainingStockCount > 0 && item.attributeCount !== 0
                              ? formatNumber(
                                    (item.attributeCount * 100) / item.remainingStockCount
                                ) + '%'
                              : ''
                  }
              ]);

    return (
        <div className="wound-type-report mt-5">
            <h2 className="mb-2">{t('mortalityAnalysis.woundType.title')}</h2>
            <Spin spinning={loading}>
                <Row gutter={[8, 8]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Card>
                            <div className="flex justify-end">
                                <Button
                                    className={
                                        woundTypeReportType ===
                                        WOUND_TYPE_WITH_DEAD_FISH_COUNT_GRAPH
                                            ? BUTTON_BLUE_SELECTED
                                            : BUTTON_BLUE_UN_SELECTED
                                    }
                                    onClick={() =>
                                        handleGraphTypeChange(WOUND_TYPE_WITH_DEAD_FISH_COUNT_GRAPH)
                                    }
                                >
                                    {t('mortalityAnalysis.woundType.chart.mortality')}
                                </Button>
                                &nbsp;
                                <Button
                                    className={
                                        woundTypeReportType === WOUND_TYPE_WITH_STOCK_COUNT_GRAPH
                                            ? BUTTON_BLUE_SELECTED
                                            : BUTTON_BLUE_UN_SELECTED
                                    }
                                    onClick={() =>
                                        handleGraphTypeChange(WOUND_TYPE_WITH_STOCK_COUNT_GRAPH)
                                    }
                                >
                                    {t('mortalityAnalysis.woundType.chart.stockCount')}
                                </Button>
                            </div>

                            <FDStackedColumn
                                data={woundTypeReport}
                                chartConfig={{
                                    label: {
                                        position: 'middle',
                                        content: (item) => item.percentage
                                    },
                                    connectedArea: {
                                        style: (oldStyle) => ({
                                            fill: LIGHT_GRAY,
                                            stroke: oldStyle.fill
                                        })
                                    }
                                }}
                            />
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
};

export default WoundType;
