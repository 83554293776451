import { appInsights } from 'AppInsights';
import { FDFilter } from 'components';
import { withPageViewTracking } from 'hoc';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/store';
import {
    getFishwellFishIdsSelection,
    getMortalityList,
    getPensSelection,
    getSitesSelection
} from 'redux/thunks';
import TableMortalityList from './TableMortalityList';
import { FiltersContext } from 'context/filtersContext';

const MortalityList = () => {
    const dispatch = useAppDispatch();

    const [filters, setFilters] = useState({});

    const handleFilterSubmit = useCallback((query) => {
        setFilters(query);

        appInsights.trackEvent({ name: 'Mortality list filter apply' });
    }, []);

    useEffect(() => {
        dispatch(getFishwellFishIdsSelection());
        dispatch(getSitesSelection());
        dispatch(getPensSelection());
    }, []);

    return (
        <>
            <FDFilter hasSite hasPen hasFishGroupIds hasDateRange callback={handleFilterSubmit} />

            <FiltersContext.Provider value={filters}>
                <TableMortalityList />
            </FiltersContext.Provider>
        </>
    );
};

export default withPageViewTracking(MortalityList, 'Mortality list');
