export const OPENING_PAGE = '/opening';
export const FORBIDDEN_PAGE = '/forbidden';
export const UNAUTHORIZED_PAGE = '/unauthorized';
export const SESSION_EXPIRED_PAGE = '/session-expired';

export const ENGINE_COUNTING_PAGE = '/engine-counting';
export const LIVE_FISH_EVENT_PAGE = '/live-fish-event';
export const LIVE_STRESS_DETECTION_PAGE = '/live-fish-event/live-stress-detection';
export const LIVE_DEAD_FISH_COUNTING_PAGE = '/live-fish-event/live-dead-fish-counting';
export const DASHBOARD_PAGE = '/';
export const COMPARISON_PAGE = '/comparison';
export const SITE_DETAIL_PAGE = '/site-detail/:siteId';
export const PEN_DETAIL_PAGE = '/pen-detail/:penId';
export const DAILY_REPORT_PAGE = '/daily';
export const MORTALITY_LIST_PAGE = '/mortality-list';
export const MORTALITY_PAGE = '/mortality-detail';
export const MORTALITY_ANALYSIS_PAGE = '/mortality-analysis';
export const STRESS_EVENT_PAGE = '/stress-detail';
export const STRESS_ANALYSIS_PAGE = '/stress-analysis';
export const STRESS_LIST_PAGE = '/stress-list';
export const FLOW_PAGE = '/flow';
export const SCORING_EVENT_PAGE = '/scoring';
export const PROCESS_PAGE = '/process';
export const SITE_MANAGEMENT_PAGE = '/site';
export const PEN_PAGE = '/pen';
export const FISH_GROUP_PAGE = '/fishwell-fish-id';
export const OTHER_DEAD_FISH_COUNT_PAGE = '/other-dead-fish-count';
export const FISH_GROUP_DETAIL_PAGE = '/fishwell-fish-id/detail';
export const USER_PAGE = '/user';
export const GUEST_USER_INVITATION_PAGE = '/guest-user-invitation';
export const PROFILE_PAGE = '/profile';
export const ORGANIZATION_PAGE = '/organization';
export const COMPANY_CONFIG_PAGE = '/company-config';
export const USERS_RECEIVE_REPORT_PAGE = '/users-receive-report';