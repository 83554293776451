import axios from 'axios';
import { STRESS_DETECTION_ENGINE_API_KEY } from 'common/constants';
import qs from 'qs';

const instance = axios.create({
    timeout: 100000,
    maxContentLength: 10000
});

const defaultOptions = {
    headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        accept: 'application/json',
        contentType: 'application/json',
        'X-API-Key': STRESS_DETECTION_ENGINE_API_KEY
    }
};

const get = (url, params = {}, options = {}) => {
    if (!url) return;

    return instance.get(url, {
        ...defaultOptions,
        ...options,
        params,
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
    });
};

const interceptorHandleResponse = (response) => response;
const handleError = (error) => {
    return Promise.reject(error.response.data.detail);
};

const put = (url, body = {}, options = {}) =>
    instance.put(url, body, { ...defaultOptions, ...options });

instance.interceptors.response.use(interceptorHandleResponse, handleError);

export { get, put };
