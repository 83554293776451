import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    FISHWELL_FISH_ID_SELECTION_API,
    FFI_TYPE_OF_FISH_SELECTION_API,
    ORIGINAL_SITE_SELECTION_API,
    PEN_SELECTION_API,
    SERVICE_BOAT_SELECTION_API,
    SITE_SELECTION_API,
    USER_SELECTION_API
} from 'common/constants';
import { API } from 'services';

export const getSitesSelection = createAsyncThunk(
    'selections/getSitesSelection',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(SITE_SELECTION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getPensSelection = createAsyncThunk(
    'selections/getPensSelection',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(PEN_SELECTION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getOriginalSitesSelection = createAsyncThunk(
    'selections/getOriginalSitesSelection',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(ORIGINAL_SITE_SELECTION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getFishwellFishIdsSelection = createAsyncThunk(
    'selections/getFishwellFishIdsSelection',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(FISHWELL_FISH_ID_SELECTION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getServiceBoatsSelection = createAsyncThunk(
    'selections/getServiceBoatsSelection',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(SERVICE_BOAT_SELECTION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getFFITypeOfFishSelection = createAsyncThunk(
    'selections/getFFITypeOfFishSelection',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(FFI_TYPE_OF_FISH_SELECTION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getUsersSelection = createAsyncThunk(
    'selections/getUsersSelection',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(USER_SELECTION_API, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

