import { appInsights } from 'AppInsights';
import { Button, Card, Col, Row, Spin, Statistic } from 'antd';
import {
    AGGREGATE_GRAPH,
    BUTTON_BLUE_SELECTED,
    BUTTON_BLUE_UN_SELECTED,
    PER_DAY_GRAPH
} from 'common/constants';
import { formatDateTime, formatNumber } from 'common/utils';
import { FDLine } from 'components';
import { FiltersContext } from 'context/filtersContext';
import isEmpty from 'lodash/isEmpty';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { setGraphType } from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';
import { getDeadFishCountingMortality } from 'redux/thunks';
import './index.scss';

const FishMortality = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const filters = useContext(FiltersContext);

    const graphType = useSelector((s) => s.filter.data.graphType);
    const { data, loading } = useSelector((s) => s.deadFishCountingMortality);

    useEffect(() => {
        if (isEmpty(filters)) return;

        dispatch(getDeadFishCountingMortality({ ...filters, graphType: graphType }));
    }, [filters, graphType]);

    const handleGraphTypeChange = (newType) => {
        dispatch(setGraphType(newType));

        appInsights.trackEvent({
            name: 'Fish mortality chart type change',
            properties: { type: newType }
        });
    };

    return (
        <div className="fish-mortality-report mt-5">
            <h2 className="mb-2">{t('mortalityAnalysis.fishMortality.title')}</h2>
            <Spin spinning={loading}>
                <Row gutter={[8, 8]}>
                    <Col xs={24} sm={24} md={24} lg={4} xl={4}>
                        <Row gutter={[8, 8]} className="h-full">
                            {data.counts?.map((item, index) => (
                                <Col
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={24}
                                    xl={24}
                                    key={index}
                                    className={item.fishName}
                                >
                                    <Card className="flex justify-center content-center h-full card-count">
                                        <Statistic
                                            title={t(`general.fishMortality.${item.fishName}`)}
                                            value={formatNumber(item.totalAmount)}
                                            valueStyle={{ color: '#cf1322' }}
                                        />
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={20} xl={20}>
                        <Card>
                            <div className="flex justify-end">
                                <Button
                                    id="aggregate"
                                    className={
                                        graphType === AGGREGATE_GRAPH
                                            ? BUTTON_BLUE_SELECTED
                                            : BUTTON_BLUE_UN_SELECTED
                                    }
                                    onClick={() => handleGraphTypeChange(AGGREGATE_GRAPH)}
                                >
                                    {t('mortalityAnalysis.fishMortality.mortalityChart.aggregate')}
                                </Button>
                                &nbsp;
                                <Button
                                    className={
                                        graphType === PER_DAY_GRAPH
                                            ? BUTTON_BLUE_SELECTED
                                            : BUTTON_BLUE_UN_SELECTED
                                    }
                                    onClick={() => handleGraphTypeChange(PER_DAY_GRAPH)}
                                >
                                    {t('mortalityAnalysis.fishMortality.mortalityChart.perDay')}
                                </Button>
                            </div>
                            <FDLine
                                data={data.charts?.map((item) => ({
                                    ...item,
                                    type: t(`general.fishMortality.${item.name}`),
                                    value: item.amount,
                                    name: formatDateTime(item.day)
                                }))}
                            />
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
};

export default FishMortality;
