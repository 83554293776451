import { appInsights } from 'AppInsights';
import { Col, Input, Row } from 'antd';
import {
    BUTTON_AS_LINK,
    FISH_ATTRIBUTES,
    FISH_DIAGNOSTIC_ATTRIBUTES,
    FISH_GROUP_DETAIL_PAGE,
    FISH_NAMES,
    TABLE_COLUMNS
} from 'common/constants';
import { blockInvalidChar, formatNumber } from 'common/utils';
import { ExpandRow, FDTable, ViewMoreAttachments } from 'components';
import { FiltersContext } from 'context/filtersContext';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { setSelectedFishGroupId } from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';

const DataTable = ({ reports, loading, isInvalidRecord, onChange }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { expandRowDailyReport } = useFlags();

    const { fishNames } = useContext(FiltersContext);

    const [modalState, setModalState] = useState({ open: false, attributes: [] });

    const FISH_GROUPS_COLUMN = {
        title: 'general.table.fishGroups',
        dataIndex: 'fishGroups',
        className: 'table-report-fish-groups',
        render: (fishGroups) =>
            fishGroups.map((fishGroup) => (
                <button
                    className={`mr-4 inline-block ${BUTTON_AS_LINK}`}
                    key={fishGroup.id}
                    onClick={() => handleFishGroupClick(fishGroup)}
                >
                    {fishGroup.fishGroupId}-{fishGroup.sibling}
                </button>
            )),
        isDefault: true
    };

    const NOTE_COLUMN = {
        title: 'general.table.note',
        dataIndex: 'note',
        width: 170,
        isDefault: true,
        render: (_, record) => (
            <Input
                value={record.note}
                onChange={(e) => onChange(e, record.id, 'note')}
                data-testid={`note-${record.id}`}
            />
        )
    };

    const COLUMNS_FOR_DAILY_REPORT = [
        TABLE_COLUMNS.SITE,
        TABLE_COLUMNS.PEN,
        FISH_GROUPS_COLUMN,
        TABLE_COLUMNS.START_TIME,
        TABLE_COLUMNS.STOP_TIME,
        TABLE_COLUMNS.LAKS,
        TABLE_COLUMNS.ØRRET,
        TABLE_COLUMNS.BERGGYLT,
        TABLE_COLUMNS.ROGNKJEKS,
        NOTE_COLUMN
    ];

    const handleFishGroupClick = (fishGroup) => {
        dispatch(setSelectedFishGroupId(fishGroup.id));

        navigate(FISH_GROUP_DETAIL_PAGE);

        appInsights.trackEvent({
            name: 'Fish group button click',
            properties: { rootLTreePath: fishGroup.rootLTreePath }
        });
    };

    // filter columns with api response
    const convertColumns = (columns) => {
        if (fishNames?.length) {
            columns = columns.filter((col) => fishNames.includes(col.dataIndex) || col.isDefault);
        }

        return columns;
    };

    const convertReportData = (reports) =>
        reports.map((item, index) => {
            const { fishes, ...rest } = item;
            const fishData = fishes
                .filter((fish) => FISH_NAMES.some((item) => item.id === fish.fishId))
                .reduce(
                    (accumulator, { fishId, dead }) => ({
                        ...accumulator,
                        [fishId]: dead
                    }),
                    { key: index }
                );

            const attributes = fishes
                .filter((fish) => FISH_ATTRIBUTES.some((item) => item.id === fish.fishId))
                .map((fish) => ({
                    name: fish.fishId,
                    count: fish.dead,
                    attachments: fish.attachments
                }));

            const diagnosticAttributes = fishes
                .filter((fish) =>
                    FISH_DIAGNOSTIC_ATTRIBUTES.some((item) => item.id === fish.fishId)
                )
                .reduce((acc, obj) => {
                    acc[obj.fishId] = obj.dead;
                    return acc;
                }, {});

            return {
                ...rest,
                ...fishData,
                attributes,
                diagnosticAttributes
            };
        });

    const expandedRowRender = (record) => (
        <>
            <div className={`py-2 ml-10  ${isInvalidRecord(record) && 'invalid-row'}`}>
                <Row className="w-full flex items-center xs:gap-x-6 xl:gap-x-0">
                    <Col xl={6} xxl={4}>
                        <span className="font-medium underline">
                            {t('dailyReport.report.table.registerCauseOfDeath')}:
                        </span>
                    </Col>
                    <Col xl={4} xxl={3}>
                        <span className="mr-1">{t('general.table.uspess')}:</span>
                        <span>{formatNumber(record.uspess)}</span>
                    </Col>
                    <Col xl={4} xxl={3}>
                        <span className="mr-1">{t('general.table.sar')}:</span>
                        <Input
                            min={0}
                            type="number"
                            value={record.sar}
                            onChange={(e) => onChange(e, record.id, 'sar')}
                            onKeyDown={blockInvalidChar}
                            className="w-20"
                            data-testid={`sar-${record.id}`}
                        />
                    </Col>
                    <Col xl={4} xxl={3}>
                        <span className="mr-1">{t('general.table.hsmb')}:</span>
                        <Input
                            min={0}
                            type="number"
                            value={record.hsmb}
                            onChange={(e) => onChange(e, record.id, 'hsmb')}
                            onKeyDown={blockInvalidChar}
                            className="w-20"
                            data-testid={`hsmb-${record.id}`}
                        />
                    </Col>
                    <Col xl={6} xxl={3}>
                        <span className="mr-1">{t('general.table.taperfisk')}:</span>
                        <Input
                            min={0}
                            type="number"
                            value={record.taperfisk}
                            onChange={(e) => onChange(e, record.id, 'taperfisk')}
                            onKeyDown={blockInvalidChar}
                            className="w-20"
                            data-testid={`taperfisk-${record.id}`}
                        />
                    </Col>
                </Row>
            </div>

            {expandRowDailyReport && (
                <ExpandRow
                    diagnosticAttributes={record.diagnosticAttributes}
                    attributes={record.attributes}
                    viewMoreAttachments={() =>
                        setModalState({ open: true, attributes: record.attributes })
                    }
                />
            )}
        </>
    );

    return (
        <>
            <FDTable
                headers={convertColumns(COLUMNS_FOR_DAILY_REPORT)}
                data={convertReportData(reports)}
                className="report-table"
                rowClassName="table-row"
                loading={loading}
                expandable={{ expandedRowRender }}
            />

            <ViewMoreAttachments
                open={modalState.open}
                attributes={modalState.attributes}
                onClose={() => setModalState({ open: false, attributes: [] })}
            />
        </>
    );
};

DataTable.propTypes = {
    reports: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    isInvalidRecord: PropTypes.func,
    onChange: PropTypes.func
};

export default DataTable;
