import { createSelector } from '@reduxjs/toolkit';
import { PRODUCTION_FISH_NAMES } from 'common/constants';

const mortalitySelector = (state) => state.mortality;

export const mortalityDetailSelector = createSelector(
    mortalitySelector,
    (mortality) => mortality.detail
);

export const mortalityProductionFishSelector = createSelector(
    mortalityDetailSelector,
    (mortalityDetail) =>
        mortalityDetail.data.fishes?.find((fish) =>
            PRODUCTION_FISH_NAMES.some((productionFish) => productionFish.id === fish.fishName)
        ) ?? {}
);
