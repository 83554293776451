import { createAsyncThunk } from '@reduxjs/toolkit';
import { USERS_RECEIVE_REPORT_API } from 'common/constants';
import { API } from 'services';

export const getUsersReceiveReport = createAsyncThunk('usersReceiveReport/getUsersReceiveReport', async (filters, { rejectWithValue }) => {
    try {
        const { data } = await API.get(USERS_RECEIVE_REPORT_API, filters);
        return data;
    } catch (err) {
        return rejectWithValue(err);
    }
});

export const createUsersReceiveReport = createAsyncThunk(
    'usersReceiveReport/createUsersReceiveReport', async (item, { rejectWithValue }) => {
        try {
            const { data } = await API.post(`${USERS_RECEIVE_REPORT_API}`, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }                                 
    });

export const editUsersReceiveReport = createAsyncThunk(
    'usersReceiveReport/editUsersReceiveReport',
    async ({ id, item }, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${USERS_RECEIVE_REPORT_API}/${id}`, item);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const deleteUsersReceiveReport = createAsyncThunk(
    'usersReceiveReport/deleteUsersReceiveReport', async (id, { rejectWithValue }) => {
        try {
            const { data } = await API.delete(`${USERS_RECEIVE_REPORT_API}/${id}`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    });
