import { appInsights } from 'AppInsights';
import { formatDateTime } from 'common/utils';
import { FDFilter, FDSliderSelection } from 'components';
import { useDidMountEffect } from 'hooks/useDidMountEffect';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { setSelectedMortalityId } from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';
import {
    getFishwellFishIdsSelection,
    getMortalityDetail,
    getMortalityDetailList,
    getPensSelection,
    getSitesSelection
} from 'redux/thunks';

const FilterBar = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { data: mortalities, loading } = useSelector((s) => s.mortality.detailList);

    const selectedMortalityId = useSelector((s) => s.filter.data.selectedMortalityId);

    useEffect(() => {
        dispatch(getSitesSelection());
        dispatch(getPensSelection());
        dispatch(getFishwellFishIdsSelection());
    }, []);

    useDidMountEffect(() => {
        handleMortalityChange(
            (mortalities.some((mortality) => mortality.id === selectedMortalityId) &&
                selectedMortalityId) ||
                mortalities[0]?.id ||
                null
        );
    }, [mortalities]);

    const handleMortalityChange = (mortalityId) => {
        dispatch(setSelectedMortalityId(mortalityId));

        if (mortalityId) {
            var mortalityIds =
                mortalities.find((mortality) => mortality.id === mortalityId)?.ids || [];

            dispatch(getMortalityDetail({ ids: mortalityIds }));
        }

        appInsights.trackEvent({ name: 'Selected mortality change', properties: { mortalityId } });
    };

    const handleFilterSubmit = useCallback((query) => {
        dispatch(getMortalityDetailList(query));

        appInsights.trackEvent({ name: 'Mortality filter apply' });
    }, []);

    return (
        <>
            <FDFilter hasSite hasPen hasFishGroupIds hasDateRange callback={handleFilterSubmit} />

            <div className="filter-bar my-2">
                <div className="filter p-2.5 md:text-base">
                    <div className="font-semibold">{t('mortality.fishMortalities')}</div>

                    <FDSliderSelection
                        selectedId={selectedMortalityId}
                        listSelectItem={mortalities.map((mortality) => ({
                            id: mortality.id,
                            content: (
                                <div>
                                    <div>{formatDateTime(mortality.stopTime)}</div>
                                    <div>{mortality.site}</div>
                                    <div>
                                        {t('general.table.penNumber')}: {mortality.pen}
                                    </div>
                                </div>
                            )
                        }))}
                        loading={loading}
                        onSelected={handleMortalityChange}
                    />
                </div>
            </div>
        </>
    );
};

export default FilterBar;
