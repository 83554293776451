export const PRODUCTION_FISH_NAMES = [
    {
        id: 'laks',
        text: 'general.fishMortality.laks'
    },
    {
        id: 'ørret',
        text: 'general.fishMortality.ørret'
    }
];

export const FISH_NAMES = [
    ...PRODUCTION_FISH_NAMES,
    {
        id: 'berggylt',
        text: 'general.fishMortality.berggylt'
    },
    {
        id: 'rognkjeks',
        text: 'general.fishMortality.rognkjeks'
    }
];

// The type of fish is shown the mortality rate on the dashboard page
export const WOUND_TYPE = [
    {
        id: 'wound',
        text: 'general.fishAttribute.wound'
    },
    {
        id: 'infected_wound',
        text: 'general.fishAttribute.infectedWound'
    },
    {
        id: 'mechanical_wound',
        text: 'general.fishAttribute.mechanicalWound'
    },
    {
        id: 'no_wound',
        text: 'general.fishAttribute.noWound'
    }
];

export const OTHER_ATTRIBUTES = [
    {
        id: 'fillet',
        text: 'general.fishAttribute.fillet'
    },
    {
        id: 'looser_fish',
        text: 'general.fishAttribute.looserFish'
    }
];

export const FISH_ATTRIBUTES = [...WOUND_TYPE, ...OTHER_ATTRIBUTES];

export const FISH_DIAGNOSTIC_ATTRIBUTES = [
    {
        id: 'number_of_analyze',
        text: 'general.fishDiagnosticAttribute.numberOfAnalysis'
    },
    {
        id: 'average_length',
        text: 'general.fishDiagnosticAttribute.averageLength'
    },
    {
        id: 'size_outlier',
        text: 'general.fishDiagnosticAttribute.sizeOutlier'
    },
    {
        id: 'wound_percentage',
        text: 'general.fishDiagnosticAttribute.woundPercentage'
    }
];
