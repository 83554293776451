import { DownloadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row } from 'antd';
import { appInsights } from 'AppInsights';
import { FILE_EXPORT_FORMAT_DATE, FISH_ATTRIBUTES } from 'common/constants';
import { exportZipOfImages } from 'common/utils';
import { WoundAndSizeAttachment } from 'components';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { mortalityProductionFishSelector } from 'redux/selector';

const WoundAttachment = () => {
    const { t } = useTranslation();
    const [isDownloading, setIsDownloading] = useState(false);

    const productionFish = useSelector(mortalityProductionFishSelector);

    const combinedAttributes = FISH_ATTRIBUTES.map((attribute) => {
        const match = productionFish.attributes?.find((item) => item.name === attribute.id);
        return { ...attribute, ...match };
    });

    const downloadAttachments = async () => {
        if (isEmpty(productionFish.attributes)) return;

        setIsDownloading(true);

        const folders = combinedAttributes.map((attribute) => ({
            name: t(attribute.text),
            imageUrls: attribute.attachments
        }));

        const fileName = `${t('mortality.woundAndSizeImages')} - ${moment().format(
            FILE_EXPORT_FORMAT_DATE
        )}`;

        await exportZipOfImages(folders, fileName);

        setIsDownloading(false);
        appInsights.trackEvent({ name: 'Wound and size images download' });
    };

    return (
        <>
            <div className="mt-5 mb-2 flex justify-between items-center">
                <h2 className="mb-0">{t('mortality.woundAndSizeImages')}</h2>
                <Button
                    onClick={() => downloadAttachments()}
                    disabled={isEmpty(productionFish.attributes)}
                    icon={<DownloadOutlined />}
                    loading={isDownloading}
                >
                    {t('button.download')}
                </Button>
            </div>
            <Card>
                <Row gutter={16}>
                    {combinedAttributes.map((attribute) => (
                        <Col className="mb-2" key={attribute.id} xs={24} sm={12} lg={8}>
                            <span className="font-medium text-base">{t(attribute.text)}</span>
                            <WoundAndSizeAttachment
                                attachments={attribute.attachments}
                                limit={attribute.attachments?.length}
                            />
                        </Col>
                    ))}
                </Row>
            </Card>
        </>
    );
};

export default WoundAttachment;
