import { MessageOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { appInsights } from 'AppInsights';
import { BUTTON_BLUE_CLASS } from 'common/constants';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import EventNotesDrawer from './EventNotesDrawer';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';

const EventNotes = () => {
    const { t } = useTranslation();

    const { showEventNotes } = useFlags();
    const loading = useSelector((s) => s.eventDetail.info.loading);

    const [isOpenEventNotes, setIsOpenEventNotes] = useState(false);
    const [hasNewEventNote, setHasNewEventNote] = useState(false);

    const toggleEventNotesCollapsible = () => {
        setIsOpenEventNotes(!isOpenEventNotes);

        appInsights.trackEvent({
            name: 'Event notes collapsible toggle',
            properties: { isOpenEventNotes: !isOpenEventNotes }
        });
    };

    if (!showEventNotes) return;

    return (
        <Spin spinning={loading}>
            <EventNotesDrawer
                isOpenEventNotes={isOpenEventNotes}
                toggleEventNotesCollapsible={toggleEventNotesCollapsible}
                setHasNewEventNote={setHasNewEventNote}
            />

            <div className="fixed bottom-4 right-7">
                <Tooltip placement="topRight" title={t('eventDetail.eventNote.title')}>
                    <Button
                        onClick={toggleEventNotesCollapsible}
                        className={`${BUTTON_BLUE_CLASS} rounded-full w-10 h-10 mb-2 ${
                            hasNewEventNote ? 'note-button' : ''
                        }`}
                        icon={<MessageOutlined className="text-xl" />}
                    />
                </Tooltip>
            </div>
        </Spin>
    );
};

export default EventNotes;
