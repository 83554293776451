import {
    BoxPlotOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { appInsights } from 'AppInsights';
import { Button, Col, Dropdown, Row, Space, Spin } from 'antd';
import {
    ABNORMAL_MORTALITY_EVENT_REPORT,
    BLUE,
    BUTTON_BLUE_CLASS,
    BUTTON_GRAY_CLASS,
    BUTTON_RED_CLASS,
    DEAD_FISH_DETECTION_INCORRECT_FISH_NAME,
    DEAD_FISH_DETECTION_MISSING_FISHWELL_FISH_GROUP,
    FFI_STATUS_REPORT,
    LIGHT_GRAY,
    PROBLEMATIC_STRESS_EVENT,
    SUCCESSFULLY_DELETION_MESSAGE
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { FDTable } from 'components';
import { withPageViewTracking } from 'hoc';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getUsersReceiveReport, deleteUsersReceiveReport } from 'redux/thunks';
import Swal from 'sweetalert2';
import UsersReceiveReportFormCreate from './UsersReceiveReportFormCreate';
import UsersReceiveReportFormEdit from './UsersReceiveReportFormEdit';
import Search from 'antd/es/input/Search';
import { Tabs } from 'antd';

const UsersReceiveReport = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { data: usersReceiveReport, loading } = useSelector((s) => s.usersReceiveReport);

    const [searchText, setSearchText] = useState('');
    const [usersReceiveReportTab, setUsersReceiveReportTab] = useState(DEAD_FISH_DETECTION_INCORRECT_FISH_NAME);
    const [modalCreateState, setModalCreateState] = useState({ open: false, reportType: null });
    const [modalUpdateState, setModalUpdateState] = useState({ open: false, userInfo: null });

    const columns = [
        {
            title: 'usersReceiveReport.table.name',
            dataIndex: 'name',
            key: 'name',
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
                multiple: 2
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            title: 'usersReceiveReport.table.email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'usersReceiveReport.table.status',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: 'general.table.action',
            dataIndex: 'Action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        className={`edit-user-receive-report ${BUTTON_GRAY_CLASS}`}
                        onClick={() => handleShowModal(record)}
                    >
                        <EditOutlined />
                        <span>{t('general.action.edit')}</span>
                    </Button>
                    <Button
                        className={`delete-user-receive-report ${BUTTON_RED_CLASS}`}
                        onClick={() => handleDeleteUserReceiveReport(record.id)}
                    >
                        <DeleteOutlined />
                        <span>{t('general.action.delete')}</span>
                    </Button>
                </Space>
            )
        }
    ];

    useEffect(() => {
        dispatch(getUsersReceiveReport({ reportType: usersReceiveReportTab }));
    }, []);

    const isMatchSearchText = (str) => new RegExp(searchText, 'i').test(str);

    const handleSearch = (searchText) => {
        setSearchText(searchText.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));

        appInsights.trackEvent({
            name: 'User search',
            properties: { searchText }
        });
    };

    const handleTabChange = (usersReceiveReportTab) => {
        setUsersReceiveReportTab(usersReceiveReportTab);
        dispatch(getUsersReceiveReport({ reportType: usersReceiveReportTab }));
    };

    const handleShowModal = (userInfo) => setModalUpdateState({ open: true, userInfo });

    const handleDeleteUserReceiveReport = (id = null) => {
        Swal.fire({
            title: t('general.action.deleteModal.title'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: BLUE,
            cancelButtonColor: LIGHT_GRAY,
            confirmButtonText: t('general.action.deleteModal.confirmText'),
            cancelButtonText: t('general.action.deleteModal.cancelText')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(deleteUsersReceiveReport(id)).unwrap();

                    handleReload();
                    alertSuccessMessage(SUCCESSFULLY_DELETION_MESSAGE);
                } catch (err) {
                    alertErrorMessage(err);
                }
            }
        });

        appInsights.trackEvent({ name: 'User receive report delete', properties: { siteId: id } });
    };

    const handleReload = () => {
        dispatch(getUsersReceiveReport({ reportType: usersReceiveReportTab }));
    };

    const createOptions = [
        {
            label: <Trans i18nKey="usersReceiveReport.tab.deadFishDetectionIncorrectFishName" />,
            key: 'deadFishDetectionIncorrectFishName',
            icon: <BoxPlotOutlined />,
            onClick: () => setModalCreateState({ open: true, reportType: DEAD_FISH_DETECTION_INCORRECT_FISH_NAME })
        },
        {
            label: <Trans i18nKey="usersReceiveReport.tab.deadFishDetectionMissingFFI" />,
            key: 'deadFishDetectionMissingFFI',
            icon: <BoxPlotOutlined />,
            onClick: () => setModalCreateState({ open: true, reportType: DEAD_FISH_DETECTION_MISSING_FISHWELL_FISH_GROUP })
        },
        {
            label: <Trans i18nKey="usersReceiveReport.tab.problematicStressEvent" />,
            key: 'problematicStressEvent',
            icon: <BoxPlotOutlined />,
            onClick: () => setModalCreateState({ open: true, reportType: PROBLEMATIC_STRESS_EVENT })
        },
        {
            label: <Trans i18nKey="usersReceiveReport.tab.ffiStatusReport" />,
            key: 'ffiStatusReport',
            icon: <BoxPlotOutlined />,
            onClick: () => setModalCreateState({ open: true, reportType: FFI_STATUS_REPORT })
        },
        {
            label: <Trans i18nKey="usersReceiveReport.tab.abnormalMortalityEventReport" />,
            key: 'abnormalMortalityEventReport',
            icon: <BoxPlotOutlined />,
            onClick: () => setModalCreateState({ open: true, reportType: ABNORMAL_MORTALITY_EVENT_REPORT })
        }
    ];

    return (
        <div className="users-report-management">
            <h2 className="mb-2">{t('usersReceiveReport.title')}</h2>
            <Row justify="center">
                <Col span={24}>
                    <Dropdown placement="right" arrow menu={{ items: createOptions }}>
                        <Button type="default" shape="round" className={BUTTON_BLUE_CLASS}>
                            <PlusOutlined />
                            <span>{t('usersReceiveReport.newUsersReceiveReportBtn')}</span>
                        </Button>
                    </Dropdown>
                    <div className="mb-1 my-1">
                        <Search
                            placeholder={t('usersReceiveReport.searchPlaceholder')}
                            style={{ width: 200 }}
                            onSearch={(searchText) => handleSearch(searchText)}
                        />
                    </div>
                    <Spin spinning={loading}>
                        <Tabs
                            className="engine-tabs"
                            activeKey={usersReceiveReportTab}
                            animated={true}
                            onChange={(value) => handleTabChange(value)}
                            items={[
                                {
                                    label: (
                                        <div className="flex">
                                            <BoxPlotOutlined />
                                            <Trans i18nKey="usersReceiveReport.tab.deadFishDetectionIncorrectFishName" />
                                        </div>
                                    ),
                                    key: DEAD_FISH_DETECTION_INCORRECT_FISH_NAME,
                                    children: (
                                        <FDTable
                                            headers={columns}
                                            data={usersReceiveReport.filter((userInfo) => isMatchSearchText(userInfo.name))}
                                            rowKey="id"
                                        />
                                    )
                                },
                                {
                                    label: (
                                        <div className="flex">
                                            <BoxPlotOutlined />
                                            <Trans i18nKey="usersReceiveReport.tab.deadFishDetectionMissingFFI" />
                                        </div>
                                    ),
                                    key: DEAD_FISH_DETECTION_MISSING_FISHWELL_FISH_GROUP,
                                    children: (
                                        <FDTable
                                            headers={columns}
                                            data={usersReceiveReport.filter((userInfo) => isMatchSearchText(userInfo.name))}
                                            rowKey="id"
                                        />
                                    )
                                },
                                {
                                    label: (
                                        <div className="flex">
                                            <BoxPlotOutlined />
                                            <Trans i18nKey="usersReceiveReport.tab.problematicStressEvent" />
                                        </div>
                                    ),
                                    key: PROBLEMATIC_STRESS_EVENT,
                                    children: (
                                        <FDTable
                                            headers={columns}
                                            data={usersReceiveReport.filter((userInfo) => isMatchSearchText(userInfo.name))}
                                            rowKey="id"
                                        />
                                    )
                                },
                                {
                                    label: (
                                        <div className="flex">
                                            <BoxPlotOutlined />
                                            <Trans i18nKey="usersReceiveReport.tab.ffiStatusReport" />
                                        </div>
                                    ),
                                    key: FFI_STATUS_REPORT,
                                    children: (
                                        <FDTable
                                            headers={columns}
                                            data={usersReceiveReport.filter((userInfo) => isMatchSearchText(userInfo.name))}
                                            rowKey="id"
                                        />
                                    )
                                },
                                {
                                    label: (
                                        <div className="flex">
                                            <BoxPlotOutlined />
                                            <Trans i18nKey="usersReceiveReport.tab.abnormalMortalityEventReport" />
                                        </div>
                                    ),
                                    key: ABNORMAL_MORTALITY_EVENT_REPORT,
                                    children: (
                                        <FDTable
                                            headers={columns}
                                            data={usersReceiveReport.filter((userInfo) => isMatchSearchText(userInfo.name))}
                                            rowKey="id"
                                        />
                                    )
                                }
                            ]}
                        />

                    </Spin>
                </Col>
            </Row>

            <UsersReceiveReportFormCreate
                open={modalCreateState.open}
                reportType={modalCreateState.reportType}
                onClose={() => {
                    setModalCreateState({ open: false, reportType: null });
                    handleReload()
                }}
            />

            <UsersReceiveReportFormEdit
                open={modalUpdateState.open}
                userInfo={modalUpdateState.userInfo}
                onClose={() => {
                    setModalUpdateState({ open: false, userInfo: null });
                    handleReload()
                }}
            />
        </div>
    );
};

export default withPageViewTracking(UsersReceiveReport, 'User(s) receive report management');
