import { ArrowLeftOutlined, StopOutlined } from '@ant-design/icons';
import { appInsights } from 'AppInsights';
import { Button, Checkbox, Spin } from 'antd';
import {
    BLUE,
    BUTTON_BLUE_CLASS,
    BUTTON_RED_CLASS,
    GRADIENT_CHART_CONFIG,
    LIGHT_GRAY,
    LIGHT_GREEN,
    LIGHT_ORANGE,
    LIGHT_RED,
    LIGHT_YELLOW,
    LIVE_FISH_EVENT_PAGE,
    STRESS_DETECTION_ENGINE_API_KEY,
    STRESS_ENGINE_STREAMING_VIDEO_API
} from 'common/constants';
import { alertErrorMessage, generateGradientColor } from 'common/utils';
import { FDLine, StreamingVideo } from 'components';
import { withPageViewTracking } from 'hoc';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { stressDetectionStreamingSelector, userOrganizationIdSelector } from 'redux/selector';
import { resetStressDetectionStreaming, setStressDetectionStreaming } from 'redux/slices';
import { useAppDispatch } from 'redux/store';
import { getStreamingStressLevels, setVideoUpdateStatus, stopStressEngine } from 'redux/thunks';
import { NOTIFICATION_SERVICE } from 'services';
import StreamingTimeStatus from './StreamingTimeStatus';
import Swal from 'sweetalert2';

const LiveStressDetection = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const streaming = useSelector(stressDetectionStreamingSelector);
    const userOrganizationId = useSelector(userOrganizationIdSelector);

    const [loading, setLoading] = useState(true);
    const [isStoreVideoLoading, setIsStoreVideoLoading] = useState(false);
    const stressIntervalRef = useRef();
    const STRESS_EVENT_INTERVAL = 15000; // 15 seconds

    useEffect(() => {
        if (!streaming.engineBaseUrl) {
            return;
        }

        const fetchStreamingData = async () => {
            try {
                await dispatch(
                    getStreamingStressLevels({ baseUrl: streaming.engineBaseUrl })
                ).unwrap();
            } catch (error) {
                alertErrorMessage(error);
            } finally {
                setLoading(false);
            }
        };
        fetchStreamingData();

        stressIntervalRef.current = setInterval(() => {
            dispatch(getStreamingStressLevels({ baseUrl: streaming.engineBaseUrl }));
        }, STRESS_EVENT_INTERVAL);

        return () => {
            clearInterval(stressIntervalRef.current);
            dispatch(resetStressDetectionStreaming());
        };
    }, [streaming.engineBaseUrl]);

    const handleBackButtonClick = () => navigate(LIVE_FISH_EVENT_PAGE);

    const handleStressEngineStop = () => {
        NOTIFICATION_SERVICE.pushNotification({
            title: t('liveStressDetection.notification.stopDetection'),
            duration: 10
        });

        clearInterval(stressIntervalRef.current);

        dispatch(
            setStressDetectionStreaming({
                isDetecting: false
            })
        );
    };

    // const handleStopButtonClick = () => {
    //     Swal.fire({
    //         title: t('liveStressDetection.stopConfirmation'),
    //         icon: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: BLUE,
    //         cancelButtonColor: LIGHT_GRAY,
    //         confirmButtonText: t('button.yes'),
    //         cancelButtonText: t('button.cancel')
    //     }).then(async (result) => {
    //         if (result.isConfirmed) {
    //             try {
    //                 setLoading(true);
    //                 await dispatch(
    //                     stopStressEngine({
    //                         baseUrl: streaming.engineBaseUrl,
    //                         penNumber: streaming.penNumber,
    //                         farmerOrganizationId: userOrganizationId,
    //                         serviceBoatId: streaming.serviceBoatId
    //                     })
    //                 ).unwrap();

    //                 appInsights.trackEvent({
    //                     name: 'Stop stress detection successfully',
    //                     properties: {
    //                         serviceBoatName: streaming.serviceBoatName,
    //                         penNumber: streaming.penNumber
    //                     }
    //                 });
    //             } catch (error) {
    //                 alertErrorMessage(error);

    //                 appInsights.trackEvent({
    //                     name: 'Stop stress detection failed',
    //                     properties: {
    //                         serviceBoatName: streaming.serviceBoatName,
    //                         penNumber: streaming.penNumber
    //                     }
    //                 });
    //             } finally {
    //                 setLoading(false);
    //             }
    //         }
    //     });
    // };

    const getStreamingVideoUrl = () => {
        if (!streaming.engineBaseUrl) return;

        return `${streaming.engineBaseUrl}${STRESS_ENGINE_STREAMING_VIDEO_API}?x_api_key=${STRESS_DETECTION_ENGINE_API_KEY}`;
    };

    if (!streaming.isDetecting && !streaming.engineBaseUrl) {
        return (
            <>
                <h2>{t('liveStressDetection.notFound')}</h2>
                <Button
                    className={BUTTON_BLUE_CLASS}
                    icon={<ArrowLeftOutlined />}
                    onClick={() => handleBackButtonClick()}
                >
                    {t('button.back')}
                </Button>
            </>
        );
    }

    const handleStoreVideoToggle = async () => {
        var newCheckStatus = !streaming.uploadAfterStop;
        try {
            setIsStoreVideoLoading(true);
            await dispatch(
                setVideoUpdateStatus({
                    baseUrl: streaming.engineBaseUrl,
                    store: newCheckStatus
                })
            ).unwrap();

            appInsights.trackEvent({
                name: 'Set video store status successfully',
                properties: {
                    serviceBoatName: streaming.serviceBoatName,
                    penNumber: streaming.penNumber
                }
            });
        } catch (error) {
            alertErrorMessage(error);

            appInsights.trackEvent({
                name: 'Set video store status failed',
                properties: {
                    serviceBoatName: streaming.serviceBoatName,
                    penNumber: streaming.penNumber
                }
            });
        } finally {
            setIsStoreVideoLoading(false);
        }
    };

    return (
        <>
            <div className="flex flex-col items-center text-center">
                <h2>
                    {streaming.isDetecting && <Spin className="mr-2" />}
                    <span>{`${streaming.serviceBoatName ?? ''} - ${
                        streaming.isDetecting
                            ? t('liveStressDetection.progress')
                            : t('liveStressDetection.result')
                    } ${streaming.penNumber ?? ''}`}</span>
                </h2>
                <Spin spinning={isStoreVideoLoading}>
                    <Checkbox
                        onClick={() => handleStoreVideoToggle()}
                        checked={streaming.uploadAfterStop}
                    >
                        {t('liveStressDetection.storeVideo')}
                    </Checkbox>
                </Spin>

                <StreamingTimeStatus onStressEngineStop={handleStressEngineStop} />
            </div>

            <StreamingVideo
                isEventActive={streaming.isDetecting}
                streamingVideoUrl={getStreamingVideoUrl()}
            />

            <Spin spinning={loading}>
                <FDLine
                    data={streaming.stressLevels}
                    height={300}
                    isSingleLine={true}
                    color={BLUE}
                    chartConfig={GRADIENT_CHART_CONFIG}
                    style={{
                        background: generateGradientColor({
                            colors: [LIGHT_RED, LIGHT_ORANGE, LIGHT_YELLOW, LIGHT_GREEN]
                        })
                    }}
                    className="border border-1 border-gray-600 mt-5 mx-auto xs:w-full lg-w-4/5 xl:w-3/4"
                />
            </Spin>

            <div className="mt-5 flex justify-center gap-10">
                <Button
                    className={BUTTON_BLUE_CLASS}
                    icon={<ArrowLeftOutlined />}
                    onClick={() => handleBackButtonClick()}
                >
                    {t('button.back')}
                </Button>
                {/* <Button
                    className={BUTTON_RED_CLASS}
                    icon={<StopOutlined />}
                    onClick={() => handleStopButtonClick()}
                    disabled={!streaming.isDetecting}
                >
                    {t('button.stop')}
                </Button> */}
            </div>
        </>
    );
};

export default withPageViewTracking(LiveStressDetection, 'Live Stress Detection');
