import { appInsights } from 'AppInsights';
import { Button, Form, Modal, Spin } from 'antd';
import {
    BUTTON_BLUE_CLASS,
    BUTTON_WHITE_CLASS,
    SUCCESSFULLY_CREATION_MESSAGE
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { FDMultipleSelection } from 'components';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { getUsersSelection, createUsersReceiveReport} from 'redux/thunks';

const UsersReceiveReportFormCreate = ({ open = false, reportType, onClose = () => { }, onReload = () => { } }) => {
    const [form] = Form.useForm();

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { data: usersSelection, loading } = useSelector((s) => s.userSelection);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedApplicationUserIds, setSelectedApplicationUserIds] = useState([]);

    useEffect(() => {
        dispatch(getUsersSelection());
    }, []);

    useEffect(() => {
        form.resetFields();
    }, [reportType]);

    const handleCreateUsersReceiveReport = async () => {
        try {

            var request = {reportType: reportType, usersReceiveReports: selectedUsers};
            await dispatch(createUsersReceiveReport(request)).unwrap();

            alertSuccessMessage(SUCCESSFULLY_CREATION_MESSAGE);
            form.resetFields();
            onClose();
            onReload(false);
        } catch (err) {
            alertErrorMessage(err);
        }

        appInsights.trackEvent({
            name: 'Create user(s) receive the report',
            properties: { request: request }
        });
    };

    const handleUsersChange = (applicationUserIds) => {
       
        let selectedUsers = [];

        if (applicationUserIds.length) {
            selectedUsers = usersSelection.filter((user) =>
                applicationUserIds.includes(user.id)
            ).map((selectedUser) => ({
                name: selectedUser.name,
                email: selectedUser.email
            }))
        }

        setSelectedUsers(selectedUsers);
        setSelectedApplicationUserIds(applicationUserIds);
    };

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    return (
        <Modal
            title={t('usersReceiveReport.form.create')}
            open={open}
            onCancel={handleCancel}
            onOk={onClose}
            footer={
                <>
                    <Button type="default" onClick={handleCancel} className={BUTTON_WHITE_CLASS}>
                        {t('general.form.cancel')}
                    </Button>
                    <Button onClick={form.submit} className={BUTTON_BLUE_CLASS}>
                        {t('general.form.create')}
                    </Button>
                </>
            }
        >
            <Spin spinning={loading}>
                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={handleCreateUsersReceiveReport}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item
                        label={t('general.form.user.title')}
                        name="applicationUserIds"
                        rules={[
                            {
                                required: true,
                                message: t('general.form.user.required')
                            }
                        ]}
                    >
                        <FDMultipleSelection
                            placeholder={t('general.form.user.placeholder')}
                            listSelectItem={usersSelection}
                            onChange={handleUsersChange}
                            value={selectedApplicationUserIds}
                            dataTestId="users-option"
                            showSearch={true}
                        />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

UsersReceiveReportFormCreate.propTypes = {
    open: PropTypes.bool,
    reportType: PropTypes.string,
    onClose: PropTypes.func,
    onReload: PropTypes.func
};

export default UsersReceiveReportFormCreate;
