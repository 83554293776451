import { PlayCircleOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Spin } from 'antd';
import {
    BUTTON_BLUE_CLASS,
    BUTTON_GRAY_SELECTED,
    BUTTON_GRAY_UN_SELECTED,
    BUTTON_LIGHT_GREEN_CLASS,
    DATE_TIME_FORMAT,
    VIDEO_STATUS
} from 'common/constants';
import { calculateTimeDifference, formatDateTime } from 'common/utils';
import FDMediaPlayer from 'components/common/MediaPlayer/FDMediaPlayer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setEventThrow } from 'redux/slices/stress-event/eventHandlerSlice';
import { requestEngineStoreStressEventVideo } from 'redux/thunks';

const EventInfo = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const eventThrow = useSelector((s) => s.eventHandler.eventThrow);
    const { data: eventInfo, loading } = useSelector((s) => s.eventDetail.info);

    const [playerModalState, setPlayerModalState] = useState({
        open: false,
        playing: false,
        videoURLs: []
    });

    const playVideo = (videoURLs) => {
        setPlayerModalState({ open: true, videoURLs });
    };

    const requestVideo = (eventId) => {
        dispatch(requestEngineStoreStressEventVideo(eventId));
    };

    const infoList = [
        {
            key: 'eventDetail.location',
            value: `${eventInfo.siteName ?? ''} - ${eventInfo.penNumber ?? ''}`,
            col: { xs: 12, xl: 6, xxl: 3 }
        },
        {
            key: 'eventDetail.boatName',
            value: eventInfo.boatName,
            col: { xs: 12, xl: 6, xxl: 3 }
        },
        {
            key: 'eventDetail.eventType',
            value: eventInfo.eventType,
            col: { xs: 12, xl: 6, xxl: 3 }
        },
        {
            key: 'eventDetail.time',
            value: (
                <>
                    <span>{formatDateTime(eventInfo.startTime, DATE_TIME_FORMAT)}</span>
                    {' - '}
                    <span className="inline-block">
                        {formatDateTime(eventInfo.stopTime, DATE_TIME_FORMAT)}
                    </span>
                </>
            ),
            col: { xs: 12, xl: 6, xxl: 6 }
        },
        {
            key: 'eventDetail.actualCrowdingTime',
            value: calculateTimeDifference(eventInfo.startTime, eventInfo.stopTime),
            col: { xs: 12, xl: 6, xxl: 6 }
        },
        {
            key: 'eventDetail.video',
            value: eventInfo?.isRequestable ? (
                <Button className={BUTTON_BLUE_CLASS} onClick={() => requestVideo(eventInfo.id)}>
                    <span>{t('general.action.requestVideo')}</span>
                </Button>
            ) : eventInfo?.videoStatus === VIDEO_STATUS.UPLOADING ? (
                <span className="italic text-gray-500">{t('status.uploading')}</span>
            ) : eventInfo?.videoStatus === VIDEO_STATUS.FAILED ? (
                <span>
                    <WarningOutlined className="text-red-500 mr-1" />
                    <span>{t('status.failed')}</span>
                </span>
            ) : eventInfo.videoURLs?.length > 0 ? (
                <Button
                    className={BUTTON_LIGHT_GREEN_CLASS}
                    onClick={() => playVideo(eventInfo.videoURLs)}
                >
                    <PlayCircleOutlined />
                    <span>{t('general.action.watch')}</span>
                </Button>
            ) : (
                <span>-</span>
            ),
            col: { xs: 12, xl: 6, xxl: 3 }
        }
    ];

    useEffect(() => {
        dispatch(setEventThrow(null));
    }, [eventInfo.id]);

    return (
        <Card className="event-info">
            <Spin spinning={loading}>
                <Row className="text-base" gutter={8}>
                    {infoList.map((info) => (
                        <Col key={info.key} {...info.col} className="flex flex-col mb-2">
                            <span className="font-semibold">{t(info.key)}</span>
                            <span>{info.value}</span>
                        </Col>
                    ))}
                </Row>

                <div className="w-full flex justify-start flex-wrap">
                    <Button
                        className={`${
                            eventThrow ? BUTTON_GRAY_UN_SELECTED : BUTTON_GRAY_SELECTED
                        } mr-4 mt-4 w-20`}
                        onClick={() => dispatch(setEventThrow(null))}
                    >
                        {t('eventDetail.overall')}
                    </Button>

                    {eventInfo.throws?.map((throwItem) => (
                        <Button
                            className={`${
                                eventThrow?.id === throwItem.id
                                    ? BUTTON_GRAY_SELECTED
                                    : BUTTON_GRAY_UN_SELECTED
                            } mr-4 mt-4 w-20`}
                            onClick={() => dispatch(setEventThrow(throwItem))}
                            key={throwItem.id}
                        >
                            {t('eventDetail.throw')} {throwItem.throwName}
                        </Button>
                    ))}
                </div>

                <FDMediaPlayer
                    playing={playerModalState.playing}
                    videoURLs={playerModalState.videoURLs}
                    onClosePlayerModal={() =>
                        setPlayerModalState({ open: false, videoURLs: [], playing: false })
                    }
                    open={playerModalState.open}
                />
            </Spin>
        </Card>
    );
};

export default EventInfo;
