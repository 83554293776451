import { appInsights } from 'AppInsights';
import { formatDateTime } from 'common/utils';
import { FDFilter, FDSliderSelection } from 'components';
import { useDidMountEffect } from 'hooks/useDidMountEffect';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { processListSelector } from 'redux/selector';
import { setSelectedProcessId } from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';
import {
    getPensSelection,
    getProcessFlowDetection,
    getProcessList,
    getProcessStressEvent,
    getProcessWelfareScore,
    getServiceBoatsSelection,
    getSitesSelection,
    getWaterTemperature
} from 'redux/thunks';

const FilterBar = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { data: processes, loading } = useSelector(processListSelector);

    const selectedProcessId = useSelector((s) => s.filter.data.selectedProcessId);

    useEffect(() => {
        dispatch(getServiceBoatsSelection());
        dispatch(getSitesSelection());
        dispatch(getPensSelection());
    }, []);

    useDidMountEffect(() => {
        handleProcessChange(
            (processes.some((process) => process.id === selectedProcessId) && selectedProcessId) ||
                processes[0]?.id ||
                null
        );
    }, [processes]);

    const handleProcessChange = (processId) => {
        dispatch(setSelectedProcessId(processId));

        if (processId) {
            dispatch(getProcessStressEvent(processId));
            dispatch(getProcessFlowDetection(processId));
            dispatch(getProcessWelfareScore(processId));
            dispatch(getWaterTemperature(processId));
        }

        appInsights.trackEvent({ name: 'Selected process change', properties: { processId } });
    };

    const handleFilterSubmit = useCallback((query) => {
        dispatch(getProcessList(query));

        appInsights.trackEvent({ name: 'Process filter apply' });
    }, []);

    return (
        <>
            <FDFilter hasSite hasPen hasServiceBoat hasDateRange callback={handleFilterSubmit} />

            <div className="filter-bar my-2">
                <div className="filter p-2.5 md:text-base">
                    <div className="font-semibold">{t('process.processes')}</div>

                    <FDSliderSelection
                        selectedId={selectedProcessId}
                        listSelectItem={processes.map((process) => ({
                            id: process.id,
                            content: (
                                <div>
                                    <div>{formatDateTime(process.startTime)}</div>
                                    <div>{process.siteName}</div>
                                    <div>
                                        {t('general.table.penNumber')}: {process.penNumber}
                                    </div>
                                    <div>{process.boatName}</div>
                                </div>
                            )
                        }))}
                        loading={loading}
                        onSelected={handleProcessChange}
                    />
                </div>
            </div>
        </>
    );
};

export default FilterBar;
