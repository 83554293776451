import { Card, Spin } from 'antd';
import {
    BLACK,
    BLUE,
    CRITICAL_STRESS_LINE,
    GRADIENT_CHART_CONFIG,
    LIGHT_GREEN,
    LIGHT_ORANGE,
    LIGHT_RED,
    LIGHT_YELLOW
} from 'common/constants';
import {
    formatSeconds,
    generateGradientColor,
    getDifferenceInSeconds,
    isTimeWithinSliderValues
} from 'common/utils';
import { FDLine } from 'components';
import { reduce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const StressLevel = () => {
    const { t } = useTranslation();

    const sliderValues = useSelector((s) => s.eventHandler.sliderValues);
    const { data: eventHabitat, loading } = useSelector((s) => s.eventDetail.habitat);

    const filteredData = reduce(
        eventHabitat.stressLevels,
        (accumulator, stress) => {
            const differenceInSeconds = getDifferenceInSeconds(
                eventHabitat.startTime,
                stress.stop_time
            );

            if (isTimeWithinSliderValues(sliderValues, differenceInSeconds))
                accumulator.push({
                    name: formatSeconds(differenceInSeconds),
                    value: stress.level
                });

            return accumulator;
        },
        []
    );

    return (
        <Card>
            <Spin spinning={loading}>
                <p className="font-medium text-base">{t('eventDetail.stressLevel')}</p>
                <FDLine
                    data={filteredData}
                    height={300}
                    isSingleLine={true}
                    color={BLUE}
                    chartConfig={{
                        ...GRADIENT_CHART_CONFIG,
                        annotations: [
                            {
                                type: 'line',
                                start: ['min', CRITICAL_STRESS_LINE],
                                end: ['max', CRITICAL_STRESS_LINE],
                                style: {
                                    stroke: BLACK,
                                    lineDash: [8, 8]
                                }
                            }
                        ]
                    }}
                    style={{
                        background: generateGradientColor({
                            colors: [LIGHT_RED, LIGHT_ORANGE, LIGHT_YELLOW, LIGHT_GREEN]
                        })
                    }}
                />
            </Spin>
        </Card>
    );
};

export default StressLevel;
