import {
    ADMINISTRATOR,
    COMPANY_CONFIG_PAGE,
    COMPARISON_PAGE,
    DAILY_REPORT_PAGE,
    DASHBOARD_PAGE,
    ENGINE_COUNTING_PAGE,
    FISH_GROUP_DETAIL_PAGE,
    FISH_GROUP_PAGE,
    FLOW_PAGE,
    FORBIDDEN_PAGE,
    GUEST_USER_INVITATION_PAGE,
    LAYOUT_TYPE,
    LIVE_FISH_EVENT_PAGE,
    MORTALITY_ANALYSIS_PAGE,
    MORTALITY_PAGE,
    OPENING_PAGE,
    OTHER_DEAD_FISH_COUNT_PAGE,
    PEN_DETAIL_PAGE,
    PEN_PAGE,
    PROCESS_PAGE,
    PROFILE_PAGE,
    SCORING_EVENT_PAGE,
    SESSION_EXPIRED_PAGE,
    SITE_DETAIL_PAGE,
    SITE_MANAGEMENT_PAGE,
    SITE_MANAGER,
    STRESS_ANALYSIS_PAGE,
    LIVE_STRESS_DETECTION_PAGE,
    STRESS_EVENT_PAGE,
    UNAUTHORIZED_PAGE,
    USER,
    USER_PAGE,
    LIVE_DEAD_FISH_COUNTING_PAGE,
    STRESS_LIST_PAGE,
    MORTALITY_LIST_PAGE,
    USERS_RECEIVE_REPORT_PAGE
} from 'common/constants';
import Layout from 'layouts';
import {
    CompanyConfig,
    Comparison,
    DailyReport,
    DashBoard,
    EngineCounting,
    FishGroup,
    FishGroupDetail,
    Flow,
    Forbidden,
    GuestUserInvitation,
    LiveDeadFishCounting,
    LiveFishEvent,
    LiveStressDetection,
    MortalityList,
    Mortality,
    MortalityAnalysis,
    NotFound,
    Opening,
    Organization,
    OtherDeadFishCount,
    Pen,
    PenDetail,
    Process,
    Profile,
    ScoringEvent,
    SessionExpired,
    Site,
    SiteDetail,
    StressEventList,
    StressAnalysis,
    StressEvent,
    Unauthorized,
    User,
    UsersReceiveReport
} from 'pages';

const routes = [
    {
        path: OPENING_PAGE,
        name: 'Opening',
        element: <Opening />,
        isPrivate: true,
        role: USER
    },
    {
        path: FORBIDDEN_PAGE,
        name: 'Forbidden',
        element: <Forbidden />
    },
    {
        path: UNAUTHORIZED_PAGE,
        name: 'Unauthorized',
        element: <Unauthorized />
    },
    {
        path: SESSION_EXPIRED_PAGE,
        name: 'Session Expired',
        element: <SessionExpired />
    },
    {
        path: ENGINE_COUNTING_PAGE,
        element: <Layout layout={LAYOUT_TYPE.COUNTING} />,
        isPrivate: true,
        role: USER,
        children: [
            {
                path: ENGINE_COUNTING_PAGE,
                name: 'Engine Counting',
                element: <EngineCounting />,
                isPrivate: true,
                role: USER
            }
        ]
    },
    {
        path: LIVE_FISH_EVENT_PAGE,
        element: <Layout layout={LAYOUT_TYPE.COUNTING} />,
        isPrivate: true,
        role: USER,
        children: [
            {
                path: LIVE_FISH_EVENT_PAGE,
                name: 'Live fish event',
                element: <LiveFishEvent />,
                isPrivate: true,
                role: USER
            },
            {
                path: LIVE_STRESS_DETECTION_PAGE,
                name: 'Live stress detection',
                element: <LiveStressDetection />,
                isPrivate: true,
                role: SITE_MANAGER
            },
            {
                path: LIVE_DEAD_FISH_COUNTING_PAGE,
                name: 'Live dead fish counting',
                element: <LiveDeadFishCounting />,
                isPrivate: true,
                role: USER
            }
        ]
    },
    {
        path: 'organization',
        name: 'Organization',
        element: <Organization />
    },
    {
        path: '/',
        element: <Layout />,
        isPrivate: true,
        role: USER,
        children: [
            {
                path: PROFILE_PAGE,
                name: 'Profile',
                element: <Profile />,
                isPrivate: true,
                role: USER
            },
            {
                path: SITE_DETAIL_PAGE,
                name: 'Site detail',
                element: <SiteDetail />,
                isPrivate: true,
                role: USER
            },
            {
                path: PEN_DETAIL_PAGE,
                name: 'Pen detail',
                element: <PenDetail />,
                isPrivate: true,
                role: USER
            },
            {
                path: DASHBOARD_PAGE,
                name: 'Dashboard',
                element: <DashBoard />,
                isPrivate: true,
                role: USER
            },
            {
                path: COMPARISON_PAGE,
                name: 'Comparison',
                element: <Comparison />,
                isPrivate: true,
                role: USER
            },
            {
                path: DAILY_REPORT_PAGE,
                name: 'Daily',
                element: <DailyReport />,
                isPrivate: true,
                role: USER
            },
            {
                path: MORTALITY_LIST_PAGE,
                name: 'Mortality list',
                element: <MortalityList />,
                isPrivate: true,
                role: USER
            },
            {
                path: MORTALITY_PAGE,
                name: 'Mortality',
                element: <Mortality />,
                isPrivate: true,
                role: USER
            },
            {
                path: MORTALITY_ANALYSIS_PAGE,
                name: 'Mortality analysis',
                element: <MortalityAnalysis />,
                isPrivate: true,
                role: USER
            },
            {
                path: STRESS_EVENT_PAGE,
                name: 'Stress event',
                element: <StressEvent />,
                isPrivate: true,
                role: USER
            },
            {
                path: STRESS_ANALYSIS_PAGE,
                name: 'Stress analysis',
                element: <StressAnalysis />,
                isPrivate: true,
                role: USER
            },
            {
                path: STRESS_LIST_PAGE,
                name: 'Stress event list',
                element: <StressEventList />,
                isPrivate: true,
                role: USER
            },
            {
                path: FLOW_PAGE,
                name: 'Flow',
                element: <Flow />,
                isPrivate: true,
                role: USER
            },
            {
                path: SCORING_EVENT_PAGE,
                name: 'Scoring event',
                element: <ScoringEvent />,
                isPrivate: true,
                role: USER
            },
            {
                path: PROCESS_PAGE,
                name: 'Process',
                element: <Process />,
                isPrivate: true,
                role: USER
            },
            {
                path: COMPANY_CONFIG_PAGE,
                name: 'Company config',
                element: <CompanyConfig />,
                isPrivate: true,
                role: ADMINISTRATOR
            },
            {
                path: USERS_RECEIVE_REPORT_PAGE,
                name: 'User(s) report',
                element: <UsersReceiveReport />,
                isPrivate: true,
                role: SITE_MANAGER
            },
            {
                path: USER_PAGE,
                name: 'User',
                element: <User />,
                isPrivate: true,
                role: ADMINISTRATOR
            },
            {
                path: GUEST_USER_INVITATION_PAGE,
                name: 'Guest user invitation',
                element: <GuestUserInvitation />,
                isPrivate: true,
                role: ADMINISTRATOR
            },
            {
                path: SITE_MANAGEMENT_PAGE,
                name: 'Site',
                element: <Site />,
                isPrivate: true,
                role: ADMINISTRATOR
            },
            {
                path: PEN_PAGE,
                name: 'Pen',
                element: <Pen />,
                isPrivate: true,
                role: SITE_MANAGER
            },
            {
                path: FISH_GROUP_PAGE,
                name: 'FishGroup',
                element: <FishGroup />,
                isPrivate: true,
                role: SITE_MANAGER
            },
            {
                path: OTHER_DEAD_FISH_COUNT_PAGE,
                name: 'OtherDeadFishCount',
                element: <OtherDeadFishCount />,
                isPrivate: true,
                role: SITE_MANAGER
            },
            {
                path: FISH_GROUP_DETAIL_PAGE,
                name: 'FishGroupDetail',
                element: <FishGroupDetail />,
                isPrivate: true,
                role: USER
            },
            {
                path: '*',
                element: <NotFound />,
                isPrivate: true,
                role: USER
            }
        ]
    },
    {
        path: '*',
        element: <NotFound />
    }
];

export default routes;
