import { PlayCircleOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { BUTTON_RED_CLASS } from 'common/constants';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';

export default function FDMediaPlayer({
    open = false,
    videoURLs,
    playing,
    onClosePlayerModal = () => {}
}) {
    const { t } = useTranslation();
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

    const playVideoAtIndex = (index) => {
        setCurrentVideoIndex(index);
    };

    return (
        <Modal
            title={`Video ${currentVideoIndex + 1}`}
            open={open}
            footer={
                <div className="justify-self-start">
                    <h3 className="justify-self-start">Playlist</h3>
                    <div className="justify-start">
                        {videoURLs.map((videoUrl, index) => (
                            <Button
                                className={BUTTON_RED_CLASS}
                                onClick={() => playVideoAtIndex(index)}
                                disabled={index === currentVideoIndex}
                            >
                                <PlayCircleOutlined />
                                <span>
                                    {index === currentVideoIndex
                                        ? t('general.action.playing')
                                        : t('general.action.watch')}{' '}
                                    {t('button.video')} {index + 1}
                                </span>
                            </Button>
                        ))}
                    </div>
                </div>
            }
            centered={true}
            onCancel={onClosePlayerModal}
            maskClosable={false}
            width="50%"
            destroyOnClose
        >
            <ReactPlayer
                url={videoURLs[currentVideoIndex]}
                width="100%"
                height="100%"
                controls
                playing={playing}
                stopOnUnmount={true}
            />
        </Modal>
    );
}
