import { Col, Row, Spin } from 'antd';
import { useSelector } from 'react-redux';
import CrowdingArea from './CrowdingArea';
import CurrentSpeed from './CurrentSpeed';
import Efficiency from './Efficiency';
import EventInfo from './EventInfo';
import EventNotes from './EventNotes';
import FishHealthCondition from './FishHealthCondition';
import FishMortality from './FishMortality';
import OxygenLevel from './OxygenLevel';
import SliderFilter from './SliderFilter';
import StressLevel from './StressLevel';
import StressZone from './StressZone';
import Temperature from './Temperature';

const StressEventDetail = () => {
    const selectedStressEventId = useSelector((s) => s.filter.data.selectedStressEventId);
    const eventDetailListLoading = useSelector((s) => s.eventDetailList.loading);

    if (!selectedStressEventId) return;

    return (
        <div className="event-detail mt-2">
            <Spin spinning={eventDetailListLoading}>
                <EventInfo />

                <SliderFilter />

                <Row gutter={[8, 8]} className="mt-2">
                    <Col xs={24} xl={12}>
                        <StressLevel />
                    </Col>

                    <Col xs={24} xl={12}>
                        <StressZone />
                    </Col>

                    <Col xs={24} xl={12}>
                        <OxygenLevel />
                    </Col>

                    <Col xs={24} xl={12}>
                        <FishHealthCondition />
                    </Col>

                    <Col xs={24} xl={12}>
                        <Efficiency />
                    </Col>

                    <Col xs={24} xl={12}>
                        <Temperature />
                    </Col>

                    <Col xs={24} xl={12}>
                        <CurrentSpeed />
                    </Col>

                    <Col xs={24} xl={12}>
                        <CrowdingArea />
                    </Col>
                </Row>

                <FishMortality />

                <EventNotes />
            </Spin>
        </div>
    );
};

export default StressEventDetail;
