import { Button, Card, Spin } from 'antd';
import { appInsights } from 'AppInsights';
import {
    BUTTON_BLUE_SELECTED,
    BUTTON_BLUE_UN_SELECTED,
    EFFICIENCY_TOGGLE,
    X_AXIS
} from 'common/constants';
import { formatSeconds, getDifferenceInSeconds, isTimeWithinSliderValues } from 'common/utils';
import { FDLine } from 'components';
import { flatMap } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Efficiency = () => {
    const { t } = useTranslation();

    const sliderValues = useSelector((s) => s.eventHandler.sliderValues);
    const { data: eventChannels, loading } = useSelector((s) => s.eventDetail.channels);

    const [toggleEfficiencyState, setToggleEfficiencyState] = useState(
        EFFICIENCY_TOGGLE.FISH_PER_30_SECONDS
    );

    const numberOfFishes = flatMap(eventChannels.channels, (channel) => {
        let previousNumberFish;
        return channel.numberFishes
            ?.map((numberFish) => {
                const differenceInSeconds = getDifferenceInSeconds(
                    eventChannels.startTime,
                    numberFish.stop_time
                );

                const newNumberFish = {
                    type: `${t('eventDetail.channel')} ${channel.channelName}`,
                    differenceInSeconds: differenceInSeconds,
                    name: formatSeconds(differenceInSeconds),
                    value:
                        numberFish.value &&
                        previousNumberFish &&
                        numberFish.value > previousNumberFish
                            ? numberFish.value - previousNumberFish
                            : 0
                };
                previousNumberFish = numberFish.value;

                return newNumberFish;
            })
            .filter((numberFish) =>
                isTimeWithinSliderValues(sliderValues, numberFish.differenceInSeconds)
            );
    });

    const tonPerHours = flatMap(
        eventChannels.channels,
        (channel) =>
            channel.tonPerHours
                ?.map((tonPerHour) => {
                    const differenceInSeconds = getDifferenceInSeconds(
                        eventChannels.startTime,
                        tonPerHour.stop_time
                    );

                    const newTonPerHour = {
                        type: `${t('eventDetail.channel')} ${channel.channelName}`,
                        differenceInSeconds: differenceInSeconds,
                        name: formatSeconds(differenceInSeconds),
                        value: tonPerHour.value < 0 ? 0 : tonPerHour.value
                    };

                    return newTonPerHour;
                })
                .filter((tonPerHour) =>
                    isTimeWithinSliderValues(sliderValues, tonPerHour.differenceInSeconds)
                ) ?? []
    );

    const handleToggleStateChange = (state) => {
        setToggleEfficiencyState(state);
        appInsights.trackEvent({
            name: 'Event efficiency toggle state change',
            properties: { filterBy: state }
        });
    };

    return (
        <Card>
            <Spin spinning={loading}>
                <div className="flex justify-between">
                    <p className="font-medium text-base">{t('eventDetail.efficiency')}</p>
                    <div className="flex justify-end">
                        <Button
                            className={
                                toggleEfficiencyState === EFFICIENCY_TOGGLE.FISH_PER_30_SECONDS
                                    ? BUTTON_BLUE_SELECTED
                                    : BUTTON_BLUE_UN_SELECTED
                            }
                            onClick={() =>
                                handleToggleStateChange(EFFICIENCY_TOGGLE.FISH_PER_30_SECONDS)
                            }
                            style={{ marginRight: '4px' }}
                        >
                            {t('eventDetail.fishPer30Seconds')}
                        </Button>

                        <Button
                            className={
                                toggleEfficiencyState === EFFICIENCY_TOGGLE.TON_PER_HOUR
                                    ? BUTTON_BLUE_SELECTED
                                    : BUTTON_BLUE_UN_SELECTED
                            }
                            onClick={() => handleToggleStateChange(EFFICIENCY_TOGGLE.TON_PER_HOUR)}
                        >
                            {t('eventDetail.tonPerHour')}
                        </Button>
                    </div>
                </div>
                <FDLine
                    data={
                        toggleEfficiencyState === EFFICIENCY_TOGGLE.FISH_PER_30_SECONDS
                            ? numberOfFishes
                            : tonPerHours
                    }
                    chartConfig={{ xAxis: X_AXIS }}
                    height={300}
                />
            </Spin>
        </Card>
    );
};

export default Efficiency;
