import { createAsyncThunk } from '@reduxjs/toolkit';
import { MORTALITY_API } from 'common/constants';
import { API } from 'services';

export const getMortalityList = createAsyncThunk(
    'mortalities/getMortalityList',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${MORTALITY_API}/list`, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getMortalityDetailList = createAsyncThunk(
    'mortalities/getMortalityDetailList',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${MORTALITY_API}/detail-list`, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const getMortalityDetail = createAsyncThunk(
    'mortalities/getMortalityDetail',
    async (filters, { rejectWithValue }) => {
        try {
            const { data } = await API.get(`${MORTALITY_API}/detail`, filters);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const updateFishMortality = createAsyncThunk(
    'mortalities/updateFishMortality',
    async (updatingFishMortality, { rejectWithValue }) => {
        try {
            const { data } = await API.put(`${MORTALITY_API}`, updatingFishMortality);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const deleteFishMortality = createAsyncThunk(
    'mortalities/deleteFishMortality',
    async (ids, { rejectWithValue }) => {
        try {
            const queryString = ids.map((id) => `ids=${id}`).join('&');
            const { data } = await API.delete(`${MORTALITY_API}?${queryString}`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);

export const restoreFishMortality = createAsyncThunk(
    'mortalities/restoreFishMortality',
    async (ids, { rejectWithValue }) => {
        try {
            const queryString = ids.map((id) => `ids=${id}`).join('&');
            const { data } = await API.put(`${MORTALITY_API}/restore?${queryString}`);
            return data;
        } catch (err) {
            return rejectWithValue(err);
        }
    }
);
