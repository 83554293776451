import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    GlobalOutlined,
    RestOutlined,
    RollbackOutlined
} from '@ant-design/icons';
import { Button, Space, Spin, Tabs } from 'antd';
import { appInsights } from 'AppInsights';
import {
    ADMINISTRATOR,
    BLUE,
    BUTTON_AS_LINK,
    BUTTON_BLUE_CLASS,
    BUTTON_GRAY_CLASS,
    BUTTON_RED_CLASS,
    FISH_GROUP_DETAIL_PAGE,
    LIGHT_GRAY,
    MORTALITY_PAGE,
    SITE_MANAGER,
    SUCCESSFULLY_DELETION_MESSAGE,
    SUCCESSFULLY_RESTORE_MESSAGE,
    TABLE_COLUMNS
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import { FDTable } from 'components';
import { FiltersContext } from 'context/filtersContext';
import { isEmpty } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedFishGroupId, setSelectedMortalityId } from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';
import {
    deleteFishMortality,
    getFFITypeOfFishSelection,
    getMortalityList,
    restoreFishMortality
} from 'redux/thunks';
import Swal from 'sweetalert2';
import MortalityForm from '../MortalityForm';
import useAppAbility from 'casl/can';

const TableMortalityList = () => {
    const { t } = useTranslation();
    const { can } = useAppAbility();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const filters = useContext(FiltersContext);

    const { data: mortalities, loading } = useSelector((s) => s.mortality.list);
    const { loading: fishGroupLoading } = useSelector((s) => s.ffiTypeOfFishSelection);
    const [fishMortalityFormModalState, setFishMortalityFormModalState] = useState({
        open: false,
        fishMortality: null
    });
    const [isDeleteTab, setIsDeleteTab] = useState(false);

    useEffect(() => {
        if (isEmpty(filters)) return;

        dispatch(getMortalityList({ ...filters, isDeleted: isDeleteTab }));
    }, [filters, isDeleteTab]);

    useEffect(() => {
        if (isEmpty(filters)) return;
        dispatch(getFFITypeOfFishSelection(filters));
    }, [filters.fromDate, filters.toDate]);

    const handleFishMortalityFormShowModal = (record) => {
        setFishMortalityFormModalState({ open: true, fishMortality: record });

        appInsights.trackEvent({
            name: 'Fish mortality edit button click',
            properties: { fishMortalityId: record.id }
        });
    };

    const handleMortalityClick = (mortalityId) => {
        dispatch(setSelectedMortalityId(mortalityId));

        navigate(MORTALITY_PAGE);

        appInsights.trackEvent({
            name: 'Mortality detail button click',
            properties: { mortalityId }
        });
    };

    const handleFishGroupClick = (fishGroup) => {
        dispatch(setSelectedFishGroupId(fishGroup.id));

        navigate(FISH_GROUP_DETAIL_PAGE);

        appInsights.trackEvent({
            name: 'Fish group button click',
            properties: { rootLTreePath: fishGroup.rootLTreePath }
        });
    };

    // format the data to match the table
    const convertReportData = (reports) => {
        return reports?.map((item, index) => {
            let obj = Object.assign(
                { key: index },
                item,
                item.fishes.reduce(
                    (accumulator, currentValue) => ({
                        ...accumulator,
                        [currentValue.fishName]: currentValue.count
                    }),
                    {}
                )
            );

            return obj;
        });
    };

    const handleTabChange = (isDeleted) => {
        setIsDeleteTab(isDeleted);

        appInsights.trackEvent({ name: 'Mortality list tab change', properties: { isDeleted } });
    };

    const getMoralityIds = (fishes) => fishes.map((fish) => fish.deadFishDetectionId);

    const handleDeleteEvent = (fishes) => {
        const ids = getMoralityIds(fishes);

        Swal.fire({
            title: t('general.action.deleteModal.title'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: BLUE,
            cancelButtonColor: LIGHT_GRAY,
            confirmButtonText: t('general.action.deleteModal.confirmText'),
            cancelButtonText: t('general.action.deleteModal.cancelText')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(deleteFishMortality(ids)).unwrap();

                    dispatch(getMortalityList({ ...filters, isDeleted: isDeleteTab }));
                    alertSuccessMessage(t(SUCCESSFULLY_DELETION_MESSAGE));
                } catch (err) {
                    alertErrorMessage(err, t);
                }
            }
        });

        appInsights.trackEvent({ name: 'Mortality delete', properties: { mortalityIds: ids } });
    };

    const handleRestore = (fishes) => {
        const ids = getMoralityIds(fishes);

        Swal.fire({
            title: t('general.action.restoreModal.title'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: BLUE,
            cancelButtonColor: LIGHT_GRAY,
            confirmButtonText: t('general.action.restoreModal.confirmText'),
            cancelButtonText: t('general.action.cancel')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(restoreFishMortality(ids)).unwrap();

                    dispatch(getMortalityList({ ...filters, isDeleted: isDeleteTab }));
                    alertSuccessMessage(t(SUCCESSFULLY_RESTORE_MESSAGE));
                } catch (err) {
                    alertErrorMessage(err, t);
                }
            }
        });

        appInsights.trackEvent({ name: 'Mortality restore', properties: { mortalityIds: ids } });
    };

    const COLUMNS_FOR_EVENT_LIST = [
        TABLE_COLUMNS.SITE,
        TABLE_COLUMNS.PEN,
        {
            title: 'general.table.fishGroups',
            dataIndex: 'fishGroups',
            render: (fishGroups) =>
                fishGroups.map((fishGroup) => (
                    <button
                        className={`mr-4 inline-block ${BUTTON_AS_LINK}`}
                        key={fishGroup.id}
                        onClick={() => handleFishGroupClick(fishGroup)}
                    >
                        {fishGroup.fishGroupId}-{fishGroup.sibling}
                    </button>
                )),
            sorter: {
                compare: (a, b) => a.fishGroups.length - b.fishGroups.length,
                multiple: 5
            }
        },
        TABLE_COLUMNS.LAKS,
        TABLE_COLUMNS.ØRRET,
        TABLE_COLUMNS.BERGGYLT,
        TABLE_COLUMNS.ROGNKJEKS,
        TABLE_COLUMNS.START_TIME,
        TABLE_COLUMNS.STOP_TIME,
        {
            title: 'general.table.action',
            dataIndex: 'Action',
            render: (_, record) => (
                <Space size="middle">
                    {isDeleteTab ? (
                        <Button
                            className={BUTTON_GRAY_CLASS}
                            onClick={() => handleRestore(record.fishes)}
                        >
                            <RollbackOutlined />
                            <span>{t('general.action.restore')}</span>
                        </Button>
                    ) : (
                        <>
                            <Button
                                className={BUTTON_BLUE_CLASS}
                                onClick={() => {
                                    handleMortalityClick(record.id);
                                }}
                            >
                                <EyeOutlined />
                                <span>{t('mortalityList.detail')}</span>
                            </Button>

                            {can('manage', SITE_MANAGER, ['all']) && (
                                <Button
                                    className={BUTTON_GRAY_CLASS}
                                    onClick={() => handleFishMortalityFormShowModal(record)}
                                >
                                    <EditOutlined />
                                    <span>{t('general.action.edit')}</span>
                                </Button>
                            )}

                            {can('manage', ADMINISTRATOR, ['all']) && (
                                <Button
                                    className={`delete-boat ${BUTTON_RED_CLASS}`}
                                    onClick={() => handleDeleteEvent(record.fishes)}
                                >
                                    <DeleteOutlined />
                                    <span>{t('general.action.delete')}</span>
                                </Button>
                            )}
                        </>
                    )}
                </Space>
            )
        }
    ];

    return (
        <div className="mt-5">
            <h2 className="mb-2">{t('mortalityList.title')}</h2>
            <Spin spinning={loading || fishGroupLoading}>
                <Tabs
                    className="site-tabs"
                    activeKey={isDeleteTab}
                    animated={true}
                    onChange={handleTabChange}
                    items={[
                        {
                            label: (
                                <span>
                                    <GlobalOutlined />
                                    <span>{t('mortalityList.actived')}</span>
                                </span>
                            ),
                            key: false,
                            children: (
                                <FDTable
                                    headers={COLUMNS_FOR_EVENT_LIST}
                                    data={convertReportData(mortalities)}
                                />
                            )
                        },
                        {
                            label: (
                                <span>
                                    <RestOutlined />
                                    <span>{t('mortalityList.deleted')}</span>
                                </span>
                            ),
                            key: true,
                            children: (
                                <FDTable
                                    headers={COLUMNS_FOR_EVENT_LIST}
                                    data={convertReportData(mortalities)}
                                />
                            )
                        }
                    ]}
                />
            </Spin>
            <MortalityForm
                open={fishMortalityFormModalState.open}
                fishMortality={fishMortalityFormModalState.fishMortality}
                onClose={() => setFishMortalityFormModalState({ open: false, fishMortality: null })}
            />
        </div>
    );
};

export default TableMortalityList;
