import { appInsights } from 'AppInsights';
import { formatDateTime } from 'common/utils';
import { FDFilter, FDSliderSelection } from 'components';
import { useDidMountEffect } from 'hooks/useDidMountEffect';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { setSelectedStressEventId } from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';
import {
    getEventChannels,
    getEventInfo,
    getEventDetailList,
    getEventHabitat,
    getEventFishHealth,
    getPensSelection,
    getSitesSelection
} from 'redux/thunks';

const FilterBar = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { data: events, loading } = useSelector((s) => s.eventDetailList);

    const selectedStressEventId = useSelector((s) => s.filter.data.selectedStressEventId);
    const fromDate = useSelector((s) => s.filter.data.fromDate);

    useEffect(() => {
        dispatch(getSitesSelection());
        dispatch(getPensSelection());
    }, []);

    useDidMountEffect(() => {
        handleEventChange(
            (events.some((e) => e.id === selectedStressEventId) && selectedStressEventId) ||
                events[0]?.id ||
                null
        );
    }, [events]);

    const handleEventChange = (eventId) => {
        dispatch(setSelectedStressEventId(eventId));

        if (eventId) {
            dispatch(getEventInfo(eventId));
            dispatch(getEventHabitat(eventId));
            dispatch(getEventChannels(eventId));
            dispatch(getEventFishHealth(eventId));
        }

        appInsights.trackEvent({ name: 'Selected stress event change', properties: { eventId } });
    };

    const handleFilterSubmit = useCallback(
        (query) => {
            dispatch(getEventDetailList({ ...query, fromDate }));

            appInsights.trackEvent({ name: 'Mortality filter apply' });
        },
        [fromDate]
    );

    return (
        <>
            <FDFilter hasSite hasPen hasDateRange callback={handleFilterSubmit} />

            <div className="filter-bar my-2">
                <div className="filter p-2.5 md:text-base">
                    <div className="font-semibold">{t('eventDetail.events')}</div>

                    <FDSliderSelection
                        selectedId={selectedStressEventId}
                        listSelectItem={events.map((event) => ({
                            id: event.id,
                            content: (
                                <div>
                                    <div>{formatDateTime(event.date)}</div>
                                    <div>{event.siteName}</div>
                                    <div>
                                        {t('general.table.penNumber')}: {event.penNumber}
                                    </div>
                                    <div>{event.eventType}</div>
                                </div>
                            )
                        }))}
                        loading={loading}
                        onSelected={handleEventChange}
                    />
                </div>
            </div>
        </>
    );
};

export default FilterBar;
