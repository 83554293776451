import { Card, Slider, Spin } from 'antd';
import { BLUE, STRESS_EVENT_SECOND_INTERVAL } from 'common/constants';
import { formatSeconds, getDifferenceInSeconds } from 'common/utils';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSliderValues } from 'redux/slices/stress-event/eventHandlerSlice';
import './SliderFilter.scss';

const SliderFilter = () => {
    const dispatch = useDispatch();

    const { data: eventInfo, loading } = useSelector((s) => s.eventDetail.info);
    const eventThrow = useSelector((s) => s.eventHandler.eventThrow);

    const [initSliderValues, setInitSliderValues] = useState([0, 0]);
    const [sliderRange, setSliderRange] = useState([0, 0]);

    useEffect(() => {
        const { startTime, stopTime } = eventThrow ?? eventInfo;

        const startSecond = getDifferenceInSeconds(eventInfo.startTime, startTime);
        const stopSecond = getDifferenceInSeconds(eventInfo.startTime, stopTime);

        setInitSliderValues([startSecond, stopSecond]);
        setSliderRange([startSecond, stopSecond]);

        dispatch(setSliderValues([startSecond, stopSecond]));
    }, [eventInfo.id, eventThrow]);

    const getDefaultMarks = (numberOfValues = 6) => {
        const step = (sliderRange[1] - sliderRange[0]) / (numberOfValues - 1);
        let marks = {};

        for (let i = 0; i < numberOfValues - 1; i++) {
            const value =
                Math.ceil((sliderRange[0] + i * step) / STRESS_EVENT_SECOND_INTERVAL) *
                STRESS_EVENT_SECOND_INTERVAL;
            marks[value] = formatSeconds(value);
        }

        marks[sliderRange[1]] = formatSeconds(sliderRange[1]);

        return marks;
    };

    return (
        <Card className="slider-filter mt-2 px-5">
            <Spin spinning={loading}>
                <Slider
                    min={sliderRange[0]}
                    max={sliderRange[1]}
                    range={{ draggableTrack: true }}
                    step={STRESS_EVENT_SECOND_INTERVAL}
                    value={initSliderValues}
                    marks={getDefaultMarks()}
                    tooltip={{
                        open: true,
                        formatter: (value) => formatSeconds(value)
                    }}
                    trackStyle={{ backgroundColor: BLUE, height: 8, bottom: 2 }}
                    handleStyle={{
                        borderColor: BLUE
                    }}
                    onChange={(values) => setInitSliderValues(values)}
                    onAfterChange={(values) => dispatch(setSliderValues(values))}
                    controlSize={24}
                />
            </Spin>
        </Card>
    );
};

export default SliderFilter;
