import { appInsights } from 'AppInsights';
import { formatDateTime } from 'common/utils';
import { FDFilter, FDSliderSelection } from 'components';
import { useDidMountEffect } from 'hooks/useDidMountEffect';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { resetWelfareScore } from 'redux/slices';
import { setSelectedScoringEventId } from 'redux/slices/filterSlice';
import { useAppDispatch } from 'redux/store';
import {
    getPensSelection,
    getProcessWelfareScore,
    getScoringEventDetail,
    getScoringEventList,
    getSitesSelection
} from 'redux/thunks';

const FilterBar = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { data: scoringEvents, loading } = useSelector((s) => s.scoringEvent.list);

    const selectedScoringEventId = useSelector((s) => s.filter.data.selectedScoringEventId);

    useEffect(() => {
        dispatch(getSitesSelection());
        dispatch(getPensSelection());
    }, []);

    useDidMountEffect(() => {
        handleEventChange(
            (scoringEvents.some((e) => e.id === selectedScoringEventId) &&
                selectedScoringEventId) ||
                scoringEvents[0]?.id ||
                null
        );
    }, [scoringEvents]);

    const handleEventChange = (eventId) => {
        dispatch(setSelectedScoringEventId(eventId));

        const scoringEvent = scoringEvents.find(
            (scoringEvent) => scoringEvent.id === selectedScoringEventId
        );

        if (!scoringEvent) return;

        dispatch(getScoringEventDetail({ ids: scoringEvent.ids || [] }));

        if (scoringEvent.processId) {
            dispatch(getProcessWelfareScore(scoringEvent.processId));
        } else {
            dispatch(resetWelfareScore());
        }

        appInsights.trackEvent({ name: 'Selected scoring event change', properties: { eventId } });
    };

    const handleFilterSubmit = useCallback((query) => {
        dispatch(getScoringEventList(query));

        appInsights.trackEvent({ name: 'Scoring event filter apply' });
    }, []);

    return (
        <>
            <FDFilter hasSite hasPen hasDateRange callback={handleFilterSubmit} />

            <div className="filter-bar my-2">
                <div className="filter p-2.5 md:text-base">
                    <div className="font-semibold">{t('scoringEvent.events')}</div>

                    <FDSliderSelection
                        selectedId={selectedScoringEventId}
                        listSelectItem={scoringEvents.map((event) => ({
                            id: event.id,
                            content: (
                                <div>
                                    <div>{formatDateTime(event.scoringTime)}</div>
                                    <div>{event.siteName}</div>
                                    <div>
                                        {t('general.table.penNumber')}: {event.penNumber}
                                    </div>
                                    <div>{t('eventType.scoring')}</div>
                                </div>
                            )
                        }))}
                        loading={loading}
                        onSelected={handleEventChange}
                    />
                </div>
            </div>
        </>
    );
};

export default FilterBar;
