import { Card, Spin } from 'antd';
import { GREEN, X_AXIS, Y_AXIS } from 'common/constants';
import { formatSeconds, getDifferenceInSeconds, isTimeWithinSliderValues } from 'common/utils';
import { FDLine } from 'components';
import { reduce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const CrowdingArea = () => {
    const { t } = useTranslation();

    const sliderValues = useSelector((s) => s.eventHandler.sliderValues);
    const { data: eventHabitat, loading } = useSelector((s) => s.eventDetail.habitat);

    const filteredData = reduce(
        eventHabitat.crowdingAreas,
        (accumulator, crowdingArea) => {
            const differenceInSeconds = getDifferenceInSeconds(
                eventHabitat.startTime,
                crowdingArea.stop_time
            );

            if (isTimeWithinSliderValues(sliderValues, differenceInSeconds))
                accumulator.push({
                    name: formatSeconds(differenceInSeconds),
                    value: crowdingArea.area
                });

            return accumulator;
        },
        []
    );

    return (
        <Card>
            <Spin spinning={loading}>
                <p className="font-medium text-base">{t('eventDetail.crowdingArea')}</p>
                <FDLine
                    data={filteredData}
                    height={300}
                    isSingleLine={true}
                    color={GREEN}
                    chartConfig={{ xAxis: X_AXIS, yAxis: Y_AXIS }}
                />
            </Spin>
        </Card>
    );
};

export default CrowdingArea;
