import { SwapRightOutlined } from '@ant-design/icons';
import { Button, Form, InputNumber, Modal, Space, Spin } from 'antd';
import { appInsights } from 'AppInsights';
import {
    BUTTON_BLUE_CLASS,
    BUTTON_WHITE_CLASS,
    PRODUCTION_FISH_NAMES,
    SUCCESSFULLY_UPDATE_MESSAGE
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage, formatNumber } from 'common/utils';
import { FDSelection } from 'components';
import isNil from 'lodash/isNil';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { updateFishMortality } from 'redux/thunks';

const MortalityForm = ({ open = false, fishMortality, onClose = () => {} }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const selectedSiteId = Form.useWatch('siteId', form);
    const selectedPenId = Form.useWatch('penId', form);
    const fishes = Form.useWatch('fishes', form);

    const loading = useSelector((s) => s.mortality.list.loading);
    const sitesSelection = useSelector((s) => s.siteSelection.data);
    const pensSelection = useSelector((s) => s.penSelection.data);
    const fishGroups = useSelector((s) => s.ffiTypeOfFishSelection.data);

    useEffect(() => {
        form.setFieldsValue(fishMortality);
    }, [fishMortality]);

    const detectIsDirty = () => {
        const isFishCountChanged = fishes?.some((fish) => {
            const oldDeadFishCount = fishMortality?.fishes.find(
                (f) => f.deadFishDetectionId === fish.deadFishDetectionId
            )?.count;
            return fish.count !== oldDeadFishCount;
        });

        const oldProductionFish = fishMortality?.fishes.find((fish) => {
            return PRODUCTION_FISH_NAMES.some((type) => type.id === fish?.fishName);
        });

        const productionFishName = fishes?.find((fish) =>
            PRODUCTION_FISH_NAMES.some((productionFish) => productionFish.id === fish.fishName)
        )?.fishName;

        return (
            isFishCountChanged ||
            selectedSiteId !== fishMortality?.siteId ||
            selectedPenId !== fishMortality?.penId ||
            oldProductionFish?.fishName !== productionFishName
        );
    };

    const isDirty = detectIsDirty();

    const fishGroup = fishGroups.find(
        (fg) =>
            fg.penId === selectedPenId &&
            fg.siteId === selectedSiteId &&
            moment(fg?.actionDate).isSameOrBefore(moment(fishMortality?.stopTime)) &&
            (fg.inActiveDate === null ||
                moment(fg.inActiveDate).isAfter(moment(fishMortality?.startTime)))
    );

    useEffect(() => {
        if (isNil(fishGroup)) return;

        form.setFieldsValue({
            fishes: fishes?.map((fish) => ({
                ...fish,
                fishName: PRODUCTION_FISH_NAMES.some((type) => type.id === fish.fishName)
                    ? fishGroup.typeOfFish
                    : fish.fishName
            }))
        });
    }, [fishGroup]);

    const availableProductionFishes = PRODUCTION_FISH_NAMES.filter(
        (fish) => isNil(fishGroup) || fishGroup?.typeOfFish === fish.id
    ).map((type) => {
        return {
            ...type,
            text: t(type.text)
        };
    });

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    const handleSiteChange = (siteId, site) => {
        if (!site?.pens?.some((pen) => pen.id === selectedPenId)) {
            form.setFieldValue('penId', null);
        }
    };

    const handleUpdateFishMortality = async (fishMortalityUp) => {
        const productionFishName = fishMortalityUp.fishes.find((fish) =>
            PRODUCTION_FISH_NAMES.some((productionFish) => productionFish.id === fish.fishName)
        )?.fishName;

        const fishMortalityUpRequest = {
            deadFishCounts: fishMortalityUp.fishes.map((fish) => ({
                deadFishDetectionId: fish.deadFishDetectionId,
                count: fish.count
            })),
            siteId: fishMortalityUp.siteId,
            penId: fishMortalityUp.penId,
            productionFishName
        };

        try {
            await dispatch(updateFishMortality(fishMortalityUpRequest)).unwrap();
            alertSuccessMessage(SUCCESSFULLY_UPDATE_MESSAGE);
            form.resetFields();
            onClose();
        } catch (err) {
            alertErrorMessage(err);
        }

        appInsights.trackEvent({
            name: 'Fish mortality update',
            properties: { fishMortalityId: fishMortality?.id }
        });
    };

    return (
        <Modal
            title={t('mortalityList.mortalityForm.updateMortality')}
            open={open}
            onCancel={handleCancel}
            onOk={onClose}
            footer={
                <>
                    <Button type="default" onClick={handleCancel} className={BUTTON_WHITE_CLASS}>
                        {t('general.form.cancel')}
                    </Button>
                    <Button onClick={form.submit} className={BUTTON_BLUE_CLASS} disabled={!isDirty}>
                        {t('general.form.saveChange')}
                    </Button>
                </>
            }
        >
            <Spin spinning={loading}>
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 14 }}
                    onFinish={handleUpdateFishMortality}
                    autoComplete="off"
                    form={form}
                >
                    <Form.Item label={t('general.form.site.title')} name="siteId">
                        <FDSelection
                            placeholder={t('general.form.site.placeholder')}
                            listSelectItem={sitesSelection}
                            onChange={handleSiteChange}
                            allowClear={true}
                            showSearch={true}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t('general.form.pen.title')}
                        name="penId"
                        rules={[
                            {
                                required: true,
                                message: t('general.form.pen.required')
                            }
                        ]}
                        help={
                            fishGroup?.typeOfFish &&
                            `${t('general.form.fishGroupsProductionFish')}: ${t(
                                `general.fishMortality.${fishGroup?.typeOfFish}`
                            )}`
                        }
                    >
                        <FDSelection
                            placeholder={t('general.form.pen.placeholder')}
                            listSelectItem={pensSelection.filter(
                                (pen) => !selectedSiteId || pen.siteId === selectedSiteId
                            )}
                            onChange={(penId, pen) => form.setFieldValue('siteId', pen?.siteId)}
                            allowClear={true}
                            showSearch={true}
                        />
                    </Form.Item>

                    <Form.Item label={t('general.form.fishes')}>
                        <Form.List name="fishes">
                            {(subFields, subOpt) => (
                                <>
                                    {subFields.map((subField) => {
                                        const fish = fishMortality?.fishes[subField.name];

                                        var isProductionFish = PRODUCTION_FISH_NAMES.some(
                                            (prodFish) => prodFish.id === fish.fishName
                                        );

                                        return (
                                            <Space
                                                key={subField.key}
                                                className="pen-divide-space w-full"
                                            >
                                                <Form.Item name={[subField.name, 'fishName']}>
                                                    {isProductionFish ? (
                                                        <FDSelection
                                                            listSelectItem={
                                                                availableProductionFishes
                                                            }
                                                            disabled={!!fishGroup?.typeOfFish}
                                                        />
                                                    ) : (
                                                        t(`general.fishMortality.${fish.fishName}`)
                                                    )}
                                                </Form.Item>

                                                <Form.Item
                                                    name={[subField.name, 'count']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t(
                                                                'mortalityList.mortalityForm.count.required'
                                                            )
                                                        }
                                                    ]}
                                                >
                                                    <InputNumber
                                                        className="w-full"
                                                        placeholder={t(
                                                            'mortalityList.mortalityForm.count.placeholder'
                                                        )}
                                                        addonBefore={
                                                            <span className="w-20 inline-block flex justify-between">
                                                                {formatNumber(fish.count)}
                                                                <SwapRightOutlined />
                                                            </span>
                                                        }
                                                        min={0}
                                                    />
                                                </Form.Item>
                                            </Space>
                                        );
                                    })}
                                </>
                            )}
                        </Form.List>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

MortalityForm.propTypes = {
    open: PropTypes.bool,
    fishMortality: PropTypes.object,
    onClose: PropTypes.func
};

export default MortalityForm;
