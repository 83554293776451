import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import { appInsights } from 'AppInsights';
import { Button, Space } from 'antd';
import {
    BLUE,
    BUTTON_BLUE_CLASS,
    BUTTON_GRAY_CLASS,
    BUTTON_RED_CLASS,
    LIGHT_GRAY,
    SUCCESSFULLY_DELETION_MESSAGE
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage, formatDateTime, formatNumber } from 'common/utils';
import { FDTable } from 'components';
import { withPageViewTracking } from 'hoc';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/store';
import { deleteOtherDeadFishCount, getOtherDeadFishCounts } from 'redux/thunks';
import Swal from 'sweetalert2';
import OtherDeadFishCountForm from './OtherDeadFishCountForm';

const OtherDeadFishCount = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { data: otherDeadFishCounts, loading } = useSelector((s) => s.otherDeadFishCount);

    const [modalState, setModalState] = useState({
        open: false,
        otherDeadFishCount: null
    });

    const columns = [
        {
            dataIndex: 'fishName',
            title: 'otherDeadFishCount.table.fishName',
            render: (fishName) => t(`general.fishMortality.${fishName}`),
            sorter: {
                compare: (a, b) => a.fishName.localeCompare(b.fishName),
                multiple: 1
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            dataIndex: 'count',
            title: 'otherDeadFishCount.table.count',
            render: (fish) => formatNumber(fish)
        },
        {
            dataIndex: 'stopCountingTime',
            title: 'general.table.date',
            render: (stopCountingTime) => formatDateTime(stopCountingTime),
            sorter: {
                compare: (a, b) => moment(a.stopCountingTime) - moment(b.stopCountingTime),
                multiple: 2
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            dataIndex: 'siteName',
            title: 'general.table.siteName',
            sorter: {
                compare: (a, b) => a.siteName.localeCompare(b.siteName),
                multiple: 3
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            dataIndex: 'penNumber',
            title: 'general.table.penNumber',
            sorter: {
                compare: (a, b) => a.penNumber.localeCompare(b.penNumber),
                multiple: 4
            },
            sortDirections: ['ascend', 'descend']
        },
        {
            dataIndex: 'description',
            title: 'general.table.description'
        },
        {
            title: 'general.table.action',
            dataIndex: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        className={BUTTON_GRAY_CLASS}
                        onClick={() => setModalState({ open: true, otherDeadFishCount: record })}
                    >
                        <EditOutlined />
                        <span>{t('general.action.edit')}</span>
                    </Button>
                    <Button
                        className={BUTTON_RED_CLASS}
                        onClick={() => handleDeleteOtherDeadFishCount(record.id)}
                    >
                        <DeleteOutlined />
                        <span>{t('general.action.delete')}</span>
                    </Button>
                </Space>
            )
        }
    ];

    useEffect(() => {
        dispatch(getOtherDeadFishCounts());
    }, []);

    const handleReload = () => {
        dispatch(getOtherDeadFishCounts());
    };

    const handleDeleteOtherDeadFishCount = (id = null) => {
        Swal.fire({
            title: t('general.action.deleteModal.title'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: BLUE,
            cancelButtonColor: LIGHT_GRAY,
            confirmButtonText: t('general.action.deleteModal.confirmText'),
            cancelButtonText: t('general.action.deleteModal.cancelText')
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await dispatch(deleteOtherDeadFishCount(id)).unwrap();

                    handleReload();
                    alertSuccessMessage(SUCCESSFULLY_DELETION_MESSAGE);
                } catch (err) {
                    alertErrorMessage(err);
                }
            }
        });

        appInsights.trackEvent({
            name: 'OtherDeadFishCount delete',
            properties: { otherDeadFishCountId: id }
        });
    };

    return (
        <>
            <h2 className="mb-2">{t('otherDeadFishCount.title')}</h2>

            <Button
                className={BUTTON_BLUE_CLASS}
                onClick={() => setModalState({ open: true, otherDeadFishCount: null })}
            >
                <PlusOutlined />
                <span>{t('otherDeadFishCount.newOtherDeadFishCountBtn')}</span>
            </Button>

            <FDTable
                className="mt-2"
                headers={columns}
                data={otherDeadFishCounts}
                loading={loading}
            />

            <OtherDeadFishCountForm
                open={modalState.open}
                otherDeadFishCount={modalState.otherDeadFishCount}
                onClose={() => setModalState({ open: false, otherDeadFishCount: null })}
                onReload={handleReload}
            />
        </>
    );
};

export default withPageViewTracking(OtherDeadFishCount, 'OtherDeadFishCount management');
