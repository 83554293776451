import { createSlice } from '@reduxjs/toolkit';
import {
    deleteStressEvent,
    getEventList,
    requestEngineStoreStressEventVideo,
    restoreStressEvent,
    updateStressEvent
} from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const eventListSlice = createSlice({
    name: 'eventList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getEventList.pending, (state) => {
                state.loading = true;
            })
            .addCase(getEventList.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload || initialState.data;
            })
            .addCase(getEventList.rejected, (state) => {
                state.loading = false;
                state.data = initialState.data;
            })

            .addCase(updateStressEvent.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateStressEvent.fulfilled, (state, action) => {
                state.loading = false;
                const editedEvent = action.payload;
                state.data = state.data.filter((event) => {
                    if (event.id === editedEvent.id) {
                        Object.assign(event, {
                            ...editedEvent
                        });
                    }
                    return event;
                });
            })
            .addCase(updateStressEvent.rejected, (state) => {
                state.loading = false;
            })

            .addCase(deleteStressEvent.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteStressEvent.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(deleteStressEvent.rejected, (state) => {
                state.loading = false;
            })

            .addCase(restoreStressEvent.pending, (state) => {
                state.loading = true;
            })
            .addCase(restoreStressEvent.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(restoreStressEvent.rejected, (state) => {
                state.loading = false;
            })

            .addCase(requestEngineStoreStressEventVideo.pending, (state) => {
                state.loading = true;
            })
            .addCase(requestEngineStoreStressEventVideo.fulfilled, (state, action) => {
                state.loading = false;
                const response = action.payload;
                state.data = state.data.filter((event) => {
                    if (event.id === response.id) {
                        event.videoStatus = response.videoStatus;
                        event.isRequestable = false;
                    }
                    return event;
                });
            })
            .addCase(requestEngineStoreStressEventVideo.rejected, (state) => {
                state.loading = false;
            });
    }
});
