import { appInsights } from 'AppInsights';
import { FDFilter } from 'components';
import { FiltersContext } from 'context/filtersContext';
import { withPageViewTracking } from 'hoc';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from 'redux/store';
import { getPensSelection, getServiceBoatsSelection, getSitesSelection } from 'redux/thunks';
import TableEventList from './TableEventList/TableEventList';
const StressEventList = () => {
    const dispatch = useAppDispatch();

    const [filters, setFilters] = useState({});

    const handleFilterSubmit = useCallback((query) => {
        setFilters(query);

        appInsights.trackEvent({ name: 'Stress event list filter apply' });
    }, []);

    useEffect(() => {
        dispatch(getServiceBoatsSelection());
        dispatch(getSitesSelection());
        dispatch(getPensSelection());
    }, []);

    return (
        <>
            <FDFilter hasSite hasPen hasServiceBoat hasDateRange callback={handleFilterSubmit} />

            <FiltersContext.Provider value={filters}>
                <TableEventList />
            </FiltersContext.Provider>
        </>
    );
};

export default withPageViewTracking(StressEventList, 'Stress event list');
