import { createSlice } from '@reduxjs/toolkit';
import { getNotifications, readNotification } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getNotifications.pending, (state) => {
                state.loading = true;
            })
            .addCase(getNotifications.fulfilled, (state, action) => {
                state.loading = false;
                var notifications = action.payload?.map((notification) => {
                    return {
                        ...notification,
                        notes: notification.notes ? JSON.parse(notification.notes) : null
                    };
                });
                state.data = notifications || initialState.data;
            })
            .addCase(getNotifications.rejected, (state) => {
                state.loading = false;
                state.data = initialState.data;
            })

            .addCase(readNotification.pending, (state) => {
                state.loading = true;
            })
            .addCase(readNotification.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(readNotification.rejected, (state) => {
                state.loading = false;
            });
    }
});
