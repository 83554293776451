import { Card, Col, Row, Spin } from 'antd';
import { DATE_TIME_FORMAT } from 'common/constants';
import { formatDateTime, formatNumber } from 'common/utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { processListSelector } from 'redux/selector';

const ProcessInfo = () => {
    const { t } = useTranslation();

    const selectedProcessId = useSelector((s) => s.filter.data.selectedProcessId);
    const { data: processes } = useSelector(processListSelector);
    const { data: waterTemperature, loading } = useSelector(
        (s) => s.processDetail.waterTemperature
    );

    const process = processes.find((p) => p.id === selectedProcessId);

    const infoList = [
        {
            key: 'process.location',
            value: `${process?.siteName ?? ''} - ${process?.penNumber ?? ''}`,
            col: { xs: 12, xl: 6, xxl: 6 }
        },
        {
            key: 'process.boatName',
            value: process?.boatName,
            col: { xs: 12, xl: 6, xxl: 6 }
        },
        {
            key: 'process.startTime',
            value: formatDateTime(process?.startTime, DATE_TIME_FORMAT),
            col: { xs: 12, xl: 6, xxl: 6 }
        },
        {
            key: 'process.waterTemperature',
            value: formatNumber(waterTemperature?.waterTemperature ?? '-'),
            col: { xs: 12, xl: 6, xxl: 6 }
        }
    ];

    return (
        <Card className="event-info mt-2">
            <Row className="text-base" gutter={8}>
                {infoList.map((info) => (
                    <Col key={info.key} {...info.col} className="flex flex-col mb-2">
                        <span className="font-semibold">{t(info.key)}</span>
                        <Spin spinning={info.key === 'process.waterTemperature' && loading}>
                            <span>{info.value}</span>
                        </Spin>
                    </Col>
                ))}
            </Row>
        </Card>
    );
};

export default ProcessInfo;
