import { Button, Col, Row } from 'antd';
import { OTHER_ATTRIBUTES, WOUND_TYPE } from 'common/constants';
import { formatNumber } from 'common/utils';
import { WoundAndSizeAttachment } from 'components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ExpandRow = ({ diagnosticAttributes, attributes, viewMoreAttachments }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="py-2 ml-10">
                <Row className="w-full flex items-start">
                    <Col xs={2}>
                        <span className="font-medium underline">
                            {t('dailyReport.report.table.overall')}:
                        </span>
                    </Col>

                    <Col xs={22}>
                        <Row>
                            <Col xs={8} xl={6} xxl={4}>
                                <span className="mr-1">
                                    {t('general.fishDiagnosticAttribute.numberOfAnalysis')}:
                                </span>
                                <span>{formatNumber(diagnosticAttributes?.number_of_analyze)}</span>
                            </Col>
                            <Col xs={8} xl={6} xxl={4}>
                                <span className="mr-1">
                                    {t('general.fishDiagnosticAttribute.averageLength')}:
                                </span>
                                <span>
                                    {diagnosticAttributes?.average_length != null &&
                                        `${formatNumber(diagnosticAttributes?.average_length)}cm`}
                                </span>
                            </Col>
                            <Col xs={8} xl={6} xxl={4}>
                                <span className="mr-1">
                                    {t('general.fishDiagnosticAttribute.sizeOutlier')}:
                                </span>
                                <span>
                                    {diagnosticAttributes?.size_outlier != null &&
                                        `${formatNumber(diagnosticAttributes?.size_outlier)}%`}
                                </span>
                            </Col>
                            <Col xs={8} xl={6} xxl={4}>
                                <span className="mr-1">
                                    {t('general.fishDiagnosticAttribute.woundPercentage')}:
                                </span>
                                <span>
                                    {diagnosticAttributes?.wound_percentage != null &&
                                        `${formatNumber(diagnosticAttributes?.wound_percentage)}%`}
                                </span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>

            <div className="py-2 ml-10">
                <Row className="w-full flex items-start">
                    <Col xs={2}>
                        <span className="font-medium underline">
                            {t('dailyReport.report.table.wound')}:
                        </span>
                    </Col>

                    <Col xs={22}>
                        <Row>
                            {WOUND_TYPE.map((attribute) => {
                                const data = attributes.find((item) => item.name === attribute.id);

                                return (
                                    <Col key={attribute.id} xs={8} xl={6} xxl={4}>
                                        <span className="mr-1">{t(attribute.text)}:</span>
                                        <span>{formatNumber(data?.count)}</span>
                                        <WoundAndSizeAttachment attachments={data?.attachments} />
                                    </Col>
                                );
                            })}
                        </Row>
                    </Col>
                </Row>
            </div>

            <div className="py-2 ml-10">
                <Row className="w-full flex items-start">
                    <Col xs={2}>
                        <span className="font-medium underline">
                            {t('dailyReport.report.table.others')}:
                        </span>
                    </Col>

                    <Col xs={22}>
                        <Row>
                            {OTHER_ATTRIBUTES.map((attribute) => {
                                const data = attributes.find((item) => item.name === attribute.id);

                                return (
                                    <Col key={attribute.id} xs={8} xl={6} xxl={4}>
                                        <span className="mr-1">{t(attribute.text)}:</span>
                                        <span>{formatNumber(data?.count)}</span>
                                        <WoundAndSizeAttachment attachments={data?.attachments} />
                                    </Col>
                                );
                            })}
                        </Row>
                    </Col>
                </Row>
            </div>

            <div className="flex justify-end">
                <Button onClick={viewMoreAttachments}>
                    {t('dailyReport.report.table.viewMoreImages')}
                </Button>
            </div>
        </>
    );
};

ExpandRow.propTypes = {
    diagnosticAttributes: PropTypes.object,
    attributes: PropTypes.array,
    viewMoreAttachments: PropTypes.func
};

export default ExpandRow;
