import { appInsights } from 'AppInsights';
import { Input } from 'antd';
import { Button, Form, Modal } from 'antd';
import {
    BUTTON_BLUE_CLASS,
    BUTTON_WHITE_CLASS,
    SUCCESSFULLY_EDIT_MESSAGE
} from 'common/constants';
import { alertErrorMessage, alertSuccessMessage } from 'common/utils';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'redux/store';
import { editUsersReceiveReport } from 'redux/thunks';

const UsersReceiveReportFormEdit = ({ open = false, userInfo, onClose = () => { }, onReload = () => { } }) => {
    const [form] = Form.useForm();

    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        form.setFieldsValue(userInfo);
    }, [userInfo]);

    const handleEditUsersReceiveReport = async (req) => {
        try {

            await dispatch(editUsersReceiveReport({ id: userInfo.id, item: req })).unwrap();

            alertSuccessMessage(SUCCESSFULLY_EDIT_MESSAGE);
            form.resetFields();
            onClose();
            onReload(false);
        } catch (err) {
            alertErrorMessage(err);
        }

        appInsights.trackEvent({
            name: 'Update user(s) receive the report',
            properties: { request: req }
        });
    };

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    return (
        <Modal
            title={t('usersReceiveReport.form.edit')}
            open={open}
            onCancel={handleCancel}
            onOk={onClose}
            footer={
                <>
                    <Button type="default" onClick={handleCancel} className={BUTTON_WHITE_CLASS}>
                        {t('general.form.cancel')}
                    </Button>
                    <Button onClick={form.submit} className={BUTTON_BLUE_CLASS}>
                        {t('general.form.edit')}
                    </Button>
                </>
            }
        >
            <Form
                name="basic"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                onFinish={handleEditUsersReceiveReport}
                autoComplete="off"
                form={form}
            >
                <Form.Item label="id" name="id" hidden={true} required={false}>
                    <Input placeholder="id..." />
                </Form.Item>

                <Form.Item label={t('usersReceiveReport.form.name.title')} name="name">
                    <Input placeholder={t('usersReceiveReport.form.name.placeholder')} />
                </Form.Item>

                <Form.Item label={t('usersReceiveReport.form.email.title')} name="email">
                    <Input placeholder={t('usersReceiveReport.form.email.placeholder')} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

UsersReceiveReportFormEdit.propTypes = {
    open: PropTypes.bool,
    userInfo: PropTypes.object,
    onClose: PropTypes.func,
    onReload: PropTypes.func
};

export default UsersReceiveReportFormEdit;
