import { Card, Spin } from 'antd';
import {
    BLUE,
    LIGHT_GREEN,
    LIGHT_ORANGE,
    LIGHT_RED,
    LIGHT_YELLOW,
    OXYGEN_CHART_CONFIG
} from 'common/constants';
import {
    formatSeconds,
    generateGradientColor,
    getDifferenceInSeconds,
    isTimeWithinSliderValues,
    oxygenGradient
} from 'common/utils';
import { FDLine } from 'components';
import { reduce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const OxygenLevel = () => {
    const { t } = useTranslation();

    const sliderValues = useSelector((s) => s.eventHandler.sliderValues);
    const { data: eventHabitat, loading } = useSelector((s) => s.eventDetail.habitat);

    const filteredData = reduce(
        eventHabitat.oxygens,
        (accumulator, oxygen) => {
            const differenceInSeconds = getDifferenceInSeconds(eventHabitat.startTime, oxygen.date);

            if (isTimeWithinSliderValues(sliderValues, differenceInSeconds))
                accumulator.push({
                    name: formatSeconds(differenceInSeconds),
                    value: oxygen.value
                });

            return accumulator;
        },
        []
    );

    return (
        <Card>
            <Spin spinning={loading}>
                <p className="font-medium text-base">{t('eventDetail.oxygenLevel')}</p>
                <FDLine
                    data={filteredData}
                    height={300}
                    isSingleLine={true}
                    color={BLUE}
                    chartConfig={OXYGEN_CHART_CONFIG}
                    style={{
                        background: generateGradientColor({
                            colors: [
                                LIGHT_RED,
                                LIGHT_ORANGE,
                                LIGHT_YELLOW,
                                LIGHT_GREEN,
                                LIGHT_YELLOW
                            ],
                            stops: oxygenGradient.stops
                        })
                    }}
                />
            </Spin>
        </Card>
    );
};

export default OxygenLevel;
