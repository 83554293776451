import { Spin } from 'antd';
import WelfareScore from 'pages/process/ProcessDetail/WelfareScore';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ScoringInfo from './ScoringInfo';
import ScoringPerFish from './ScoringPerFish';

const ScoringDetail = () => {
    const { t } = useTranslation();

    const selectedScoringEventId = useSelector((s) => s.filter.data.selectedScoringEventId);
    const { data: scoringDetails, loading } = useSelector((s) => s.scoringEvent.detail);

    if (!selectedScoringEventId) return;

    return (
        <Spin spinning={loading}>
            <div className="mt-5">
                {scoringDetails.map((scoring) => (
                    <div key={scoring.id}>
                        {scoring.isBeforeDelicingEvent ? (
                            <h2>{t('scoringEvent.preDelicing')}</h2>
                        ) : (
                            <h2>{t('scoringEvent.postDelicing')}</h2>
                        )}
                        <ScoringInfo scoringEvent={scoring} />
                        <ScoringPerFish scoringEvent={scoring} />
                    </div>
                ))}

                <h2>{t('scoringEvent.welfareScore')}</h2>
                <WelfareScore hasTitle={false} />
            </div>
        </Spin>
    );
};

export default ScoringDetail;
