export const NOTIFICATION_TYPE = {
    success: 'success',
    info: 'info',
    warning: 'warning',
    error: 'error'
};

export const NOTIFICATION_PLACEMENT = {
    topRight: 'topRight',
    topLeft: 'topLeft',
    bottomRight: 'bottomRight',
    bottomLeft: 'bottomLeft'
};

export const NOTIFICATION_METHOD = {
    highAverage: 'HIGH_AVERAGE_NOTIFICATION',
    highWoundAverage: 'HIGH_WOUND_AVERAGE_NOTIFICATION'
};

export const NOTIFICATION_TITLE = {
    highAverageNotification: 'notificationHub.highAverageNotification',
    highWoundAverageNotification: 'notificationHub.highWoundAverageNotification'
};
