import { Card } from 'antd';
import { WOUND_TYPE } from 'common/constants';
import { FDColumn } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { mortalityProductionFishSelector } from 'redux/selector';

const WoundCount = () => {
    const { t } = useTranslation();

    const productionFish = useSelector(mortalityProductionFishSelector);

    return (
        <Card className="mt-2">
            <p className="font-medium text-base">{t('mortality.typeOfWound')}</p>
            <FDColumn
                height={300}
                data={WOUND_TYPE.map((type) => {
                    const data = productionFish.attributes?.find((item) => item.name === type.id);

                    return {
                        name: t(type.text),
                        type: t(type.text),
                        value: data?.count
                    };
                })}
            />
        </Card>
    );
};

export default WoundCount;
