import { createSlice } from '@reduxjs/toolkit';
import { getUsersReceiveReport, createUsersReceiveReport, editUsersReceiveReport, deleteUsersReceiveReport } from 'redux/thunks';

const initialState = {
    data: [],
    loading: false
};

export const usersReceiveReportSlice = createSlice({
    name: 'usersReceiveReport',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUsersReceiveReport.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getUsersReceiveReport.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload || initialState.data;
        });
        builder.addCase(getUsersReceiveReport.rejected, (state) => {
            state.loading = false;
            state.data = initialState.data;
        });

        builder.addCase(createUsersReceiveReport.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(createUsersReceiveReport.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(createUsersReceiveReport.rejected, (state) => {
            state.loading = false;
        });

        builder.addCase(editUsersReceiveReport.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(editUsersReceiveReport.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(editUsersReceiveReport.rejected, (state) => {
            state.loading = false;
        });

        builder.addCase(deleteUsersReceiveReport.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteUsersReceiveReport.fulfilled, (state) => {
            state.loading = false;
        });
        builder.addCase(deleteUsersReceiveReport.rejected, (state) => {
            state.loading = false;
        });
    }
});
